function spaceSeparator() {

  	return function(value) {
        if(value){
          //console.log('first_value', value)
        	var chiffre =  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") ; //? parseFloat(value).toFixed(3).toString().replace('.', ',') : null;
         // console.log('second_value', value)
          return chiffre;
        }
        return '0';
    };


}

export default {
  name: 'spaceSeparator',
  fn: spaceSeparator
};

 