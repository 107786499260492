function stockController($localStorage, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;

    vm.getParametrage = () => {
        WS.get('parametrages/getIndexForDashboard', vm.access)
        .then(function (response) {
            if (response.status == 200) {
                vm.parametrages = response.data;
                 vm.validation_bl = (response.data.validation_bl) ? response.data.validation_bl : null;
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });
    }
    vm.selectUsers = function() {
        WS.get('users/selectUsers')
            .then(function(response) {
                vm.users = response.data;
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.getTotalStock = function(list){
        if( !list ) return 0;
        var total=0;
        for (var i = 0; i<list.length ; i++) {
                total = total +(parseFloat(list[i]['prix']) * Number(list[i]['quantite']));
        }
        return total;
    }

    vm.exportStock = function(user_code){  
          WS.get('stocks/getByUser/'+user_code).then(
                  function(response){
                    var count = response.data.length;

                    var filename = 'Stock-'+user_code+'-'+moment().format("DD-MM-YYYY_HH:mm:ss");
                   
                    var filetitle =  '<h4>'+ vm.societe.nom +'</h4> <strong>Adresse : </strong>'+ vm.societe.adresse +
                    '<br> <strong>Tel :</strong> '+vm.societe.telephone+' <strong> - Fax :</strong> '+vm.societe.fax
                    +'<br><strong>MF :</strong> '+vm.societe.matricule_fiscale +'<br><strong>Code En Douane :</strong> '+vm.societe.code_endouane
                    +'<br><strong>Camion :</strong>'+vm.user.trace.depot.type+''+ vm.user.trace.depot.libelle
                    +'<br><strong>Commercial :</strong> '+vm.user.nom +' '+vm.user.prenom
                    +'<br><strong>Date :</strong>'+moment().format("DD-MM-YYYY")
                    +'<br><strong>Destination :</strong> '+vm.user.trace.depot.adresse
                    +'<br><strong>validite : </strong> 48 H'
                    +'<br>  <div style="text-align: center;"><strong>BON DE SORTIE N° : '+vm.user.trace.depot.max_bl +'</strong></div><br>' ;
                     

                        var file = {
                              sheetid: vm.societe.nom ,
                              headers: true,
                              caption: {
                                  title:filetitle
                              },
                              column: {
                                  style:'font-size:20px;background:#d1d1d1;'
                              },
                              columns: [
                                  
                                  {columnid:'produit',title: 'Porduit'},
                                  {columnid:'quantite',title: 'Quantite'}
                               
                              ],
                              row: {
                                  style: function(sheet,row,rowidx){
                                      return 'text-align:center;background:'+(rowidx%2?'#fff':'#f1f1f1')+';height:100px!important;';
                                  }
                              }
                        };
                       alasql('SELECT * INTO XLS("'+ filename +'.xls",?) FROM ?',[file,response.data.stocks]);
                  },
                  function(error){
                      console.log(error);
                  }
          );
    };

    /*
    vm.getHorsStock = function(user_code){  
          WS.get('stocks/horsStock/'+user_code).then(
                  function(response){
                    var count = response.data.length;

                    var filename = 'Stock-'+user_code+'-'+moment().format("DD-MM-YYYY_HH:mm:ss");
                   
                    var filetitle =  '<h4>'+ vm.societe[0].nom +'</h4> <strong>Adresse : </strong>'+ vm.societe[0].adresse +
                    '<br> <strong>Tel :</strong> '+vm.societe[0].telephone+' <strong> - Fax :</strong> '+vm.societe[0].fax
                    +'<br><strong>MF :</strong> '+vm.societe[0].matricule_fiscale +'<br><strong>Code En Douane :</strong> '+vm.societe[0].code_endouane
                    +'<br><strong>Camion :</strong>'+vm.user.trace.depot.type+''+ vm.user.trace.depot.libelle
                    +'<br><strong>Commercial :</strong> '+vm.user.nom +' '+vm.user.prenom
                    +'<br><strong>Date :</strong>'+moment().format("DD-MM-YYYY")
                    +'<br><strong>Destination :</strong> Grand Tunis'
                    +'<br>  <div style="text-align: center;"><strong>BON DE SORTIE N° : '+vm.user.trace.depot.max_bl +'</strong></div><br>' ;
                     

                        var file = {
                              sheetid: vm.societe[0].nom ,
                              headers: true,
                              caption: {
                                  title:filetitle
                              },
                              column: {
                                  style:'font-size:20px;background:#d1d1d1;'
                              },
                              columns: [
                                  
                                  {columnid:'code',title: 'Code'},
                                  {columnid:'produit',title: 'Porduit'}
                               
                              ],
                              row: {
                                  style: function(sheet,row,rowidx){
                                      return 'text-align:center;background:'+(rowidx%2?'#fff':'#f1f1f1')+';height:100px!important;';
                                  }
                              }
                        };
                       alasql('SELECT * INTO XLS("'+ filename +'.xls",?) FROM ?',[file,response.data]);
                  },
                  function(error){
                      console.log(error);
                  }
          );
    };
    */



    vm.getSocietes = function() {
        WS.get('societes')
            .then(function(response) {
                vm.societes = response.data;
                vm.selected_societe = vm.societes[0].code
                vm.global_societe = vm.societes[0];
                    $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.getFirstSousSociete = function() {
        WS.get('soussocietes/first')
            .then(function(response) {
                vm.societe = response.data;
                vm.getAllSousSocietes();
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    }


    vm.getAllSousSocietes = function() {
        WS.get('soussocietes')
            .then(function(response) {
                vm.soussocietes = response.data;
                vm.selected_soussociete = vm.societe.code;
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.changeSociete = ()=> {
        console.log('societes', vm.societes, vm.selected_societe)
        if(vm.selected_societe){
            vm.global_societe = vm.societes.filter(societe => societe.code == vm.selected_societe)[0];
            console.log( vm.global_societe);
        }
        
    }

    vm.changeSoussociete = ()=> {
        vm.societe = vm.soussocietes.filter(societe => societe.code == vm.selected_soussociete)[0];
        console.log(vm.societe, vm.selected_soussociete);
    }



      vm.getUserByCode = function(code) {
        WS.get('users/byCode/'+code)
            .then(function(response) {
                vm.user = response.data;
                vm.selected_soussociete = vm.user.soussociete_code ? vm.user.soussociete_code : vm.selected_soussociete
                vm.selected_societe = vm.selected_soussociete ? vm.selected_soussociete.societe_code : vm.selected_societe
                console.log('selecteeeed', vm.selected_soussociete, vm.selected_soussociete.societe_code, vm.societes)
                 vm.changeSoussociete();
                 vm.changeSociete();

                vm.date =new Date();
                    $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    }



      vm.getStock = function(user_code){
          WS.get('stocks/getByUser/'+user_code)
              .then(function(response) {
                vm.stocks = response.data.stocks;
                vm.total_stocks = response.data.total_stocks;
                $scope.$apply();
              })
              .then(null, function(error) {
                  console.log(error);
              });
      };

       vm.getHorsStock = function(user_code, check_action){
        if(check_action == true){
          WS.get('stocks/horsStock/'+user_code)
            .then(function(response) {
                vm.stocks = response.data;
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
          }else{
            vm.getStock(user_code);
          }
        
    };


    vm.updateEtat = function(user_code){
        swal({
        title: 'Envoie Stock ',
        text: "",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, envoyer !',
        cancelButtonText: 'Non, Annuler!',
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger',
        buttonsStyling: true
      }).then(function () {
     
     WS.put('stocks/updateEtat', {user_code : user_code})
            .then(function(response) {
                swal(
                      'Succès',
                      'Etat modifiée avec Succès',
                      'success'
                    ).then(function(){
                        $window.location.reload();
                    });
            })
            .then(null, function(error) {
                console.log(error);
            });
      });
    };

    vm.saveEtatStocks = function(user_code){
        swal({
        title: 'Enregistrer Stock ',
        text: "",
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, Enregistrer !',
        cancelButtonText: 'Non, Annuler!',
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger',
        buttonsStyling: false
      }).then(function () {
     
     WS.post('stocks/saveEtatStocks', {user_code : user_code})
            .then(function(response) {
                if(response.data.success){
                    swal(
                        'Succès',
                        'Etat Stock enregistrée avec Succès',
                        'success'
                      ).then(function(){
                          //$window.location.reload();
                      });
                }
                
            })
            .then(null, function(error) {
                console.log(error);
                swal(
                    'Oups!',
                    'Oups! erreur de traitement des données',
                    'error'
                  )
            })
            .catch(null, function(error) {
                console.log(error);
                swal(
                    'Oups!',
                    'Oups! erreur de traitement des données',
                    'error'
                  )
            });
      });
    };

    


    vm.deleteAllStockByUser = function(user_code){
      if(user_code){
          swal({
              title: `Suppression du stock`,
              text: `Commercial : ${vm.user.nom}  ${vm.user.prenom} , Depot : ${vm.user.trace.depot.libelle}`,
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Oui, supprimer !',
              cancelButtonText: 'Non, Annuler!',
              confirmButtonClass: 'btn btn-success',
              cancelButtonClass: 'btn btn-danger',
              buttonsStyling: true
          }).then(function () {
           
            WS.get('stocks/deleteAllStockByUser/'+user_code)
                  .then(function(response) {
                      if(response.data.status == true){
                        $window.location.reload();
                      }
                  })
                  .then(null, function(error) {
                      console.log(error);
                  });
          });
         
        }else{
            alert('veuillez bien choisir le commercial');
        }
         
    };


    vm.printElementChargement = function PrintElem(elem)
    {
        var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`<html>
                                    <head>
                                    <title> Bon de Sortie </title>
                                    <style>.noprint{display: none;}.text-center{text-align:center}</style>
                                    <style>
                                        th{border:1px solid #ddd!important; font-size:13px;}
                                        td{border:1px solid #ddd!important; font-size:13px;}
                                        h4,h5{margin: 4px;}
                                        .flex_container {
                                            margin-top: -5px;
                                            display: flex;
                                            justify-content: space-between;
                                        }
                                        .block-prods-imp{margin-top:-40px;}
                                    </style>
                                `);
        mywindow.document.write('</head><body >');
        //mywindow.document.write('<h1>' + document.title  + '</h1>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    };
}
export default {
    name: 'stockController',
    fn: stockController
};
