function Poids() {

  return function (value) {
    if (value >= 1000 && value < 1000000) {
      var new_number = value / 1000;
      return new_number = new_number.toFixed(2) + ' Kg';
    } else if (value >= 1000000) {
      var new_number = value / 1000000;
      return new_number = new_number.toFixed(2) + ' T';
    } else if (value < 1000) {
      var new_number = value;
      return new_number = new_number.toFixed(2) + ' g';
    }
    return '0';
  };


}

export default {
  name: 'Poids',
  fn: Poids
};

