import { ar_AR } from "../lang/ar";

function converterDirective($translate, AppSettings) {
    'ngInject';

    /**
     * 
     *  DEVISE : /millimes or dinars 
     * DINARS TABS OF MONTANS DINARS
     */


    function get_dizaine(dinars, DEVISE, TabUnit, TabDiz, currentLang, montant_string, index_0, index_1, add_to_right = false) {
        var label_dinar = '';
        var type = DEVISE;
        DEVISE = $translate.instant(DEVISE);

        if (type == 'mille') {
            if ((dinars[index_1] > 1 && !dinars[index_0]) || dinars[index_0] > 0) {
                DEVISE = $translate.instant('milles');
            }
        }
        
        if (dinars[index_1]) {
            if (parseInt(dinars[index_1]) == 1) {
                var num = dinars[index_1] + dinars[index_0]
                label_dinar = TabUnit[num] + ' ' + DEVISE;
            } else {
                var num = parseInt(dinars[index_0]);
                var disaine = parseInt(dinars[index_1]);
                //cas pour 70 et 90
                /**
                 * 
                 *  */
                if ((parseInt(dinars[index_1]) == 7 || parseInt(dinars[index_1]) == 9) && currentLang != 'ar_AR') {
                    label_dinar = TabDiz[disaine] + ' ' + TabUnit[num + 10] + ' ' + DEVISE;
                } else {
                    if (currentLang != 'ar_AR') {
                        label_dinar = TabDiz[disaine] + ' ' + TabUnit[num] + ' ' + DEVISE;
                    } else {
                        var et = ""
                        if (TabUnit[num] != "") {
                            et = $translate.instant(' et ')
                        }
                        label_dinar = TabUnit[num] + ' ' + et + ' ' + TabDiz[disaine] + ' ' + DEVISE;
                    }
                }
            }
        } else {
            console.log('label_dinar0', 'label_dinar0', 'label_dinar0', dinars[index_0])

            if (dinars[index_0] > 0) {
                var num = dinars[index_0]
                label_dinar = TabUnit[num] + ' ' + DEVISE;
                console.log('label_dinar', 'label_dinar', 'label_dinar', label_dinar)
            } else {
                label_dinar = '';
            }

        }

        if (label_dinar && montant_string && currentLang == 'ar_AR') {

            if (type == 'mille' || type == 'milles') {
                if (!dinars[index_1] && dinars[index_0] == "2" && currentLang == 'ar_AR') {
                    label_dinar = $translate.instant('ألفان');
                }
                if (!dinars[index_1] && dinars[index_0] == "1" && currentLang == 'ar_AR') {
                    label_dinar = $translate.instant('ألف');
                }
            }
        }
        var et = '';
        if ((type == 'mille' || type == 'milles' || type == 'millime' || type == 'millimes')  && (dinars[index_1]>0 || dinars[index_0] >0)) {
            et = $translate.instant('et');
        }  
        console.log('1212121',type, TabUnit[num], et, dinars[index_1], dinars[index_0])
        if(add_to_right){
            label_dinar =  montant_string + ' ' + et + ' ' + label_dinar;

        }else{
            label_dinar = label_dinar + ' ' + et + ' ' + montant_string;

        }
        console.log('label_dinar555555', label_dinar);
        return label_dinar.trim();
    }
    /**
     * 
     * @param {} dinars 
     * @param {*} DEVISE 
     * @param {*} TabUnit 
     * @param {*} TabDiz 
     * @param {*} currentLang 
     * @param {*} montant_string 
     * @param {*} miller : '' or mille or million
     */
    function get_centaine(dinars, DEVISE, TabUnit, TabDiz, currentLang, montant_string, miller, index, add_to_right = false) {
        const chiffre = dinars[index];

        var string_chiffre = TabUnit[chiffre];

        string_chiffre = (chiffre == "1") ? $translate.instant('cent') : string_chiffre + ' ' + $translate.instant('cents');
        if (chiffre == "2" && currentLang == 'ar_AR') {
            string_chiffre = $translate.instant('مائتان')
        }
        var et = ""
        if (TabUnit[chiffre] != "" && currentLang == 'ar_AR') {
            et = $translate.instant('et')
        }

        if(add_to_right){
            et = (et) ? et : $translate.instant('et');
            montant_string = montant_string + ' ' + et + ' ' +  string_chiffre;
        }else{
            montant_string = string_chiffre + ' ' + et + ' ' + montant_string;
        }


        return montant_string.trim();
    }


    return {
        restrict: 'E',
        replace: true,
        transclude: false,
        scope: {
            montant: '@'
        },
        template: '<div class="mtn{{code}}">{{montant_final}}</div>',
        link: function (scope, element, attributes) {
            var montant_final = "";
            attributes.$observe("montant", function (value) {
                var currentLang = attributes.language;
                //currentLang = 'fr_FR';
                //console.log('currentLang', currentLang);
                if (currentLang == 'ar_AR') {
                    var TabUnit = Array("",
                        $translate.instant("واحد"),
                        $translate.instant("اثنان"), $translate.instant("ثلاثة"),
                        $translate.instant("أربعة"), $translate.instant("خمسة"),
                        $translate.instant("ستة"), $translate.instant("سبعة"),
                        $translate.instant("ثمانية"), $translate.instant("تسعة"),
                        $translate.instant("عشرة"), $translate.instant("أحد عشر"),
                        $translate.instant("اثنا عشر"), $translate.instant("ثلاثة عشر"),
                        $translate.instant("أربعة عشر"), $translate.instant("خمسة عشر"),
                        $translate.instant("ستة عشر"), $translate.instant("سبعة عشر"),
                        $translate.instant("ثمانية عشر"), $translate.instant("تسعة عشر")
                    );

                    var TabDiz = Array("", "",
                        $translate.instant("عشرون"), $translate.instant("ثلاثون"),
                        $translate.instant("أربعون"), $translate.instant("خمسون"),
                        $translate.instant("ستون"), $translate.instant("سبعون"),
                        $translate.instant("ثمانون"), $translate.instant("تسعون")
                    );
                } else {
                    var TabUnit = Array("",
                        $translate.instant("un"),
                        $translate.instant("deux"), $translate.instant("trois"),
                        $translate.instant("quatre"), $translate.instant("cinq"),
                        $translate.instant("six"), $translate.instant("sept"),
                        $translate.instant("huit"), $translate.instant("neuf"),
                        $translate.instant("dix"), $translate.instant("onze"),
                        $translate.instant("douze"), $translate.instant("treize"),
                        $translate.instant("quatorze"), $translate.instant("quinze"),
                        $translate.instant("seize"), $translate.instant("dix-sept"),
                        $translate.instant("dix-huit"), $translate.instant("dix-neuf")
                    );

                    var TabDiz = Array("", "",
                        $translate.instant("vingt"), $translate.instant("trente"),
                        $translate.instant("quarante"), $translate.instant("cinquante"),
                        $translate.instant("soixante"), $translate.instant("soixante"),
                        $translate.instant("quatre-vingt"), $translate.instant("quatre-vingt")
                    );
                }

                var montant = attributes.montant;
                var code = attributes.code;
                if(code){
                    scope.code=code;
                }else{
                    scope.code='';
                }
                //console.log('code ===================>', code)
                if (montant != '') {
                    montant = parseFloat(montant);
                    montant = montant.toFixed(3);
                    var blocks = montant.split('.');
                    var dinars = 0, centimes = 0;

                    if (blocks.length >= 1) {
                        dinars = blocks[0];
                    }
                    if (blocks.length >= 2) {
                        centimes = blocks[1];
                    }

                    console.log('dinars', dinars);
                    //console.log('centimes', centimes);

                   // var DEVISE = $translate.instant('dinars');
                    var DEVISE = $translate.instant(AppSettings.devise ? AppSettings.devise : 'dinars');
                    /**
                     * traitements de block des dinars
                     */
                    if(dinars != 0){
                        if (currentLang != 'ar_AR') {
                            dinars = dinars.split("").reverse();
                        } else {
                            dinars = dinars.split("").reverse();;
                        }
                    }else{
                        dinars = [];
                    }
                    console.log('dinars1', dinars);

                    if(centimes != 0){
                        if (currentLang != 'ar_AR') {
                            centimes = centimes.split("");
                        } else {
                            centimes = centimes.split("");
                        }
                    }else{
                        centimes = [];
                    }
                    centimes[0] = centimes[0]  ? centimes[0] : "0";
                    centimes[1] = centimes[1]  ? centimes[1] : "0";
                    centimes[2] = centimes[2]  ? centimes[2] : "0";
                    //console.log('centimes tab', centimes);


                    //dinars = ["8", "9", "5", "3"]   //for test
                    // dinars = ["9", "8", "1", 1, 5, 8];   //for test
                    // centimes = ["9"];   //for test
                    //dinars = ["0"]   //for test
                    //console.log('dinarssssss', dinars);
                    var montant_string = '';
                    if (dinars[0] == 1 || dinars[1]) {
                        montant_string = get_dizaine(dinars, DEVISE, TabUnit, TabDiz, currentLang, '', 0, 1);
                    }
                    console.log("montant_string_test", montant_string)
                    /**
                     * ajout des centaines
                     */
                    if (dinars[2] && dinars[2] != "0") {
                        //console.log('centaines', dinars[2]);
                        montant_string = get_centaine(dinars, DEVISE, TabUnit, TabDiz, currentLang, montant_string, '' , 2);
                        //console.log('centaines montant_string', montant_string);

                    }

                    if (dinars[3] || dinars[4]) {
                        DEVISE = 'mille';
                        montant_string = get_dizaine(dinars, DEVISE, TabUnit, TabDiz, currentLang, montant_string, 3, 4);
                    }

                    if (dinars[5] && dinars[5] != "0") {
                        //console.log('centaines', dinars[5]);
                        montant_string = get_centaine(dinars, DEVISE, TabUnit, TabDiz, currentLang, montant_string, '' , 5);
                        //console.log('centaines montant_string', montant_string);

                    }

                    /**
                     * block des millimes
                     */
                    if(centimes[0] != "0" || centimes[1] != "0" || centimes[2] != "0"){
                        if (centimes[0] && centimes[0] != "0") {
                            console.log('centaines', centimes[0]);
                            montant_string = get_centaine(centimes, DEVISE, TabUnit, TabDiz, currentLang, montant_string, '' , 0, true);

                        }

                        if (centimes[1] || centimes[2]) {
                            DEVISE = 'millimes';
                            montant_string = get_dizaine(centimes, DEVISE, TabUnit, TabDiz, currentLang, montant_string, 2, 1, true);
                        }

                        if(centimes.length==1){
                           // montant_string = montant_string + ' '+ $translate.instant('millimes');
                            montant_string = montant_string + ' '+ $translate.instant(AppSettings.millimes ? AppSettings.millimes : 'millimes');
                        }
                    }
                    console.log('montant_string finale', montant_string);

                    montant_final= montant_string;

                    console.log('class finale =======================> ', '.mtn'+scope.code);

                    // $('.mtn'+scope.code).empty();
                    // $('.mtn'+scope.code).append(montant_final);

                    scope.montant_final = montant_final;
                }

            });
            return montant_final;

        }
    };
}

export default {
    name: 'converter',
    fn: converterDirective
};