export const en_US = {
    'ordre': 'Rank',
    'perime': 'expired',
    'cheque': 'check',
    'Lait': 'milk',
    'Pots': 'pot',
    'Gajou': 'Box',
    'Ratio': 'Ratio',
    'Offre Commercial': 'commercial offer',
    'Encours Commercial par Client': 'Commercial Outstandings by Customer',
    'Attention journée sans plan de tournée': 'Attention day without tour plan',
    'Programé': 'scheduled',
    'Clients programmés  non visité': 'Unvisited Scheduled Clients',
    'Camion_Prevendeur ajoutée avec Succès': 'Vendor truck successfully added',
    'Camion_Prevendeur supprimée avec Succès': 'Vendor truck successfully deleted',
    'Total cheque': 'Total checks',
    'depense': 'expenses',
    'le': "in",
    'Retour Hier': 'return from yesterday',
    'NOMADIS': 'NOMADIS',
    'Nomadis': 'Nomadis',
    'commercial': 'commercial',
    'Camion_Prevendeur modifiée avec Succès': 'Vendor truck successfully modified ',
    'Livreur': 'Delivery man',
    'Superviseur': 'supervisor',
    'Commercial': 'Commercial',
    'commercials': 'Commercials',
    'Distributeurs': 'distributors',
    'Cashvan': 'Cashvan',
    'Pré vendeur': 'Pre seller',
    'Code Pré vendeur': 'Pre seller code',
    'vente': 'sale',
    'Vente': 'Sale',
    'ventes': 'Sales',
    'commande': 'Order',
    'Commande': 'Order',
    'commandes': 'Orders',
    'Commandes': 'Orders',
    'bl': 'delivery form',
    'Bl': 'delivery form',
    'bls': 'delivery form',
    'Bls': 'delivery form',
    'Factures': 'invoice',
    'clients_visite': 'customers visite',
    'recrutement_client': 'customer recruitment',
    'Recrutement PDV': 'POS recruitment',
    'Nombre PDV': 'POF Number',
    'recette': 'revenue',
    'credit': 'credit',
    'Credit': 'credit',
    'Recouvrment / Vente ce mois': 'Recovery / Sale this month',
    'Crédit': 'Credit',
    'Encours / Moyenne des ventes mensuelles': 'Outstanding / Average monthly sales',
    'Dernière facture': 'Last invoice',
    'Dernière recouvrement': 'Last recovery',
    'Nbre de Jours': 'Number of days',
    'Age dette': 'Debt age ',
    'Vente Total': 'Total sales ',
    'Mois': 'month',
    'Moyenne': 'Average',
    'Crédit Total': 'Total credit',
    'Nbre de Recouvrement': 'Recovery number',
    'Avoir':'return ',
    'avoir': 'return ',
    'ESP/': ' cash ',
    'espece': ' cash ',
    'Espece': ' cash ',
    'Cheque': 'checks ',
    'cheque': 'checks ',
    'cheques': 'checks ',
    'Cheques': 'checks ',
    'recouvrements': 'debt recovery ',
    'recouvrement': 'debt recovery ',
    'tx_vente': 'sell rate',
    'Taux de vente': 'sell rate',
    'tx_visite': 'Visit rate',
    'Nv Client': 'new customer',
    'R.tourne': 'Tour respect',
    'visites': 'visites',
    'visite': 'visit',
    'Visite': 'visit',
    'nombre': 'number',
    'Historique': 'tour history',
    'tournees': 'Tours',
    'Nb Tournées': 'Tour Nb',
    'Tournees': 'Tours',
    'tournee': 'tour',
    'Tournee': 'Tour',
    'Copier tournee': 'Copy tour ',
    'RC': 'commercial register',
    'societe': 'Company',
    'Sous société': 'subsidiary',
    'gouvernorat': 'governorate',
    'Region': 'region',
    'region': 'region',
    'regions': 'regions',
    'Regions': 'regions',
    'gamme': 'range',
    'Nbr Bl cloturé': 'Number of closed invoices ',
    'Nbr Bl non cloturé': 'Number of unclosed invoices ',
    'famille': 'Family',
    'sous famille': 'sub family',
    'SousFamille': 'sub family',
    'marque': 'Brand',
    'Gamme': 'Range',
    'Famille': 'Family',
    'Sous Famille': 'Sub family',
    'Taux de Remise': 'Discount rate',
    'Marque': 'Brand',
    'Nb PDV': 'Nb POS',
    'Succés': 'success',
    'Succès': 'success',
    'Croissance': 'Growth',
    'Tx': 'rate',
    'TOP': 'Top',
    'Montant': 'amount',
    'Ajouter un groupe': 'Add group',
    'Affectaion Groupe Produit': 'Product Group Assignment',
    'Ajouter une Affectation': 'Add Assignment',
    'Ajouter une marque': 'Add brand',
    'Ajouter une famille': 'Add family',
    'Ajouter une sous famille': 'Add sub family',
    'Ajouter une gamme': 'Add range',
    'Ventes par gamme Aujourd\'hui': 'Sales by range Today',
    'Ventes par gamme mois': 'Sales by range month',
    'Ventes par gamme année': 'Sales by range year',
    'Commandes par gamme Aujourd\'hui': 'Orders by range Today',
    'Commandes par gamme mois': 'Orders by range per month',
    'Commandes par gamme année': 'Orders by range per year',
    'Crédit/Rec': 'credit',
    'Déconnexion': 'Logout',
    'Taux de Couverture client par vendeur (%)': 'Coverage rate customer by seller (%)',
    'nbr de visite * 100 / objectif.total )  / v.nbr_jour_travaille': 'nbr of visits * 100 / objective.total ) / v.nbr_day_worked',
    'Chiffre d\'affaire Net (DT)': 'Net turnover (DT)',
    'Chiffre d\'affaire Net Commande (DT)': "Net Order Turnover (DT)",
    'Total Net': ' Total Net',
    'Chiffre d\'affaire': 'Turnover',
    'Moyenne CA': 'average turnover',
    'nbre de client avec vente / nbre de  client visité': 'number of customers with sale / number of customers visited',
    'Tx.D': 'Distribution rate',
    'DN.G': 'digital distribution',
    'Rapport Commande Par Region/Mois': 'Order Report By Region/Month',
    'Oui': 'Yes',
    'Non': 'No',
    'Arreté le présent Bon de livraison a la somme de': 'Arrested this Delivery Note for the sum of',
    'Arreté le présent Recouvrement a la somme de': 'Arrested this Recovery in the sum of',
    'Arreté le présent Avoir a la somme de': 'Arrested the present Credit has the sum of',
    'Arreté la présente commande a la somme de': 'Arrested this order has the sum of',
    'Arreté la présente Facture a la somme de': 'Arrested this Invoice has the sum of',
    'Timbre': 'Stamp',
    'Banque': 'Bank',
    'Date_echeance': 'due date',
    'Ref': 'Ref',
    'Cheque impayé': 'Unpaid cheque',
    'Sens ecriture': 'writing direction',
    'Debiteur': 'Debtor',
    'Débit': 'Debit  ',
    'Crediteur': 'creditor',
    'Numero Cheque': 'Cheque number',
    'BL_N°': 'Invoice Number',
    'Num Chéque': 'Cheque number',
    'Paiement': 'payment',
    'Paiement Cheque': 'Payment by check',
    'Mouvement du Client': 'Customer movement',
    'Date Echeance Cheque': 'Due Date Check',
    'Date Echeance': 'Due date',
    'Banque Cheque': 'bank check',
    'Num Cheque': 'Check number',
    'Nouveau utilisateur':'new user',
    'Modifier utilisateur': 'Edit User',
    'Changer mot de passe': 'Change password',
    'Date Echeance Traite': 'Due date Draft',
    'Traite': 'draft',
    'Banque Traite': 'Draft bank',
    'Num Traite': 'Draft bank',
    'login': 'Login',
    'Mot de passe actuelle': 'Current Password',
    'Nouveau mot de passe': 'new password',
    'Confirmer Nouveau mot de passe': 'Confirm the new password',
    'Ajouter un localite': 'Add a locality',
    'Vente par Marque': 'Sale by Brand',
    'Vente par fournisseur': 'Sale by supplier',
    'Vente par Famille': 'Sale by family',
    'Commande par Marque': 'Order by Brand',
    'Commande par Famille': 'Order by family',
    'Correction Recouvrement': 'Correction Recovery',
    //dates
    'aujourdhui': 'Today',
    'Aujourd\'hui': 'Today',
    'mois': 'Month',
    'date': 'date',
    'Date': 'Date',
    'Date Prevu de livraison': 'Expected delivery date',
    'jour': 'the',
    'Jour': 'the',
    'Date Début': 'Start date',
    'Date Fin': 'end date',
    'Type Visite': 'Type of visit',
    'annee': 'year',
    'Année': 'year',
    'commandes regions': 'region order',

    'Date debut Credit': ' Credit start date',
    'Date fin Credit': 'Credit end date',
    'Date debut Visite': ' Visit start date',
    'Date fin Visite': ' Visit end date',
    // kilometrage et heure
    'TimeWork': 'WorkTime',
    'TimeOut': 'TimeOut',
    'Kilometrage': 'Mileage',
    'KD': 'Start Mileage',
    'KF': 'Mileage finish',
    'Distance': 'Distance',
    'Temps': 'duration',
    'Heure debut': 'Beginning hour',
    'Heure fin': 'the end hour',
    'HD': ' Beginning hour',
    'HF': 'the end hour',
    'Box': 'Box',
    'Taux Tva': 'tax rate',

    'Rec': 'Recovery',

    "Type": 'Type',
    'Janvier': 'January',
    'Fevrier': 'February',
    'Février': 'February',
    'Mars': 'Mars',
    'Avril': 'April',
    'Mai': 'May',
    'Juin': 'June',
    'Juillet': 'July',
    'Aout': 'August',
    'Septembre': 'September',
    'Octobre': 'October',
    'Novembre': 'November',
    'Decembre': 'December',
    'KPI': 'KPI',
    'distribution': 'distribution',
    'nombre de jour travaillé': 'number of days worked',
    'Jour repos': 'day off ',
    'J.T': 'number of days worked',
    'Date prévu prochaine Visite': 'Planned date for next visit',

    // navbar 
    'Vous avez': 'You have',
    'Credit pour demain': 'credit for tomorrow',
    'Cheques pour demain': 'Checks for tomorrow',
    'nouveaux clients': 'new customers ',
    'Francais': 'French',
    'Arabe': 'Arabic',
    // menu
    'dashboard': 'Dashbord',
    'analyses': 'analysis',
    'routing': 'routing',
    'routings': 'routings',
    'Routings': 'routings',
    'routes': 'roads',
    'Routes': 'roads',
    'utilisateurs': 'Users',
    'parametrages': ' Settings',
    'clients': 'Customers',
    'Clients': 'Customers',
    'client': 'Customer',
    'Client': 'Customers',
    'tarification': 'pricing',
    'objectif': 'Objectives',
    'Valider les Objectifs': 'Validate Objectives',
    'Cadeaux': "Gifts",
    'Connecté': 'connected',
    'nv Objectifs': "Add objective",
    'Objectif': 'Objective',
    'objectifs': 'Objectives',
    'fidelites': 'fidelity',
    'zoning': 'Zoning',
    'Zoning': 'Zoning',
    'produits': 'Products',
    'produit': 'Product',
    'chargements': 'Loads',
    'equipements': 'equipment',
    'Equipements': 'equipments',
    'equipements clients': 'customer equipment',

    'prixachat': 'purchase price',
    'prixachat1': 'purchase price 1',
    'prixachat2': 'purchase price 2',
    'prixachat3': 'purchase price 3',

    'Prix achat': 'purchase price',
    'Prix achat 1': 'purchase price 1',
    'Prix achat 2': 'purchase price 2',
    'Prix achat 3': 'purchase price 3',

    'P.Details': 'P.Details',
    'P.Depositaire': 'depositary price',

    'Nb Clients': 'Nb Customers',
    'mouvements': 'mouvements',
    'Mouvements': 'mouvements',
    'dn': 'DN',
    'carburant': 'Fuel',
    'stock': 'stock',
    'rapport': 'report',
    'rapport visites': 'Visits Report',
    'Rapport Visite par Mois': 'Visit report per month',
    'caisse': 'Fund',
    'achat': 'purchase',
    'Achat': 'purchase',
    'Stock Depot': 'Deposit stock',
    'Type utilisateur': 'user type ',
    'Createur': 'Creator',
    'dlc': ' expiration date',
    'dlcC': ' expiration date',
    'DLC': ' expiration date',
    'Prix achat HT': 'Purchase price excluding tax',
    'Prix achat TTC': 'Purchase price including tax',
    'todo list': 'TODO list',
    'devise': 'currency',
    'integration': 'integration',
    'erp': 'erp',
    'ERP': 'ERP',
    'notes': 'notes',
    'Notes': 'Notes',
    'R.I.B': 'Bank account indentity',
    'Demande chargement': 'loads demand',
    'Total Commande Livré': 'Total Order Delivered',

    
    // analyses
    'Journalier': 'Daily',
    'journalier': 'Daily',
    'periodique': 'periodic',
    'periode': 'period',
    'Vendeur': 'seller',
    'Rapport Journalier': 'Daily report',
    'Rapport Journalier Produit': 'Product Daily Report',
    'Rapport Journalier SousFamille': 'Subfamily Daily Report',
    'Rapport Commercial/Mois': 'Sales Report/Month',
    'Rapport Tournée': 'Tour report ',
    'Rapport Periodique': 'Periodic report',
    'Vente Par Region': 'Sales By Region',
    'Commande Par Region': 'Order By Region',
    'Vente Periode': 'Sale Period',
    'Commande Periode': 'Period Order',
    'Vente Famille/Commercial': 'Sale Family/Commercial',
    'Vente Famille/Gamme': 'Sale Family/Range ',
    'Vente Client_Date': 'Sale Customer per Date',
    'DN_Vendeur': 'DN seller',
    'Vente sousFamille_Client': 'Sale sub Family per Customer',
    'Commande sousFamille_Client': 'Order sub Family per Customer',
    'Vente et DN par Produit': 'Selling and digital distribution according to each product ',
    'Vente et DN par Produit region': 'Selling and digital distribution according to each product and each region',
    'Analyse Tournee': 'Tour Analysis ',
    'Clients Inactifs': 'Inactive Customers',
    'Vente/Commercial': 'Sales/Commercial ',
    'Suivi Commercial': 'Commercial follow-up ',
    'Suivi Tournee': 'Tour Tracking ',
    'Gouvernorat': 'Governorate',
    'Gouvernorats': 'Governorate',
    'gouvernorats': 'Governorate',
    'Ajouter un gouvernorat': 'Add governorate',
    'Zone': 'Zone',
    'Ajouter une zone': 'Add zone',
    'Zones': 'Zones',
    'Stat Chargement': 'Loads Statistics',
    'Top 10': 'TOP 10',
    'Vente Client': 'Customer Sale',
    'Credit par Commercial': 'Credit by Saller',
    'List Credit par Commercial': 'List Credit by Commercial ',
    'Reglement par Commercial': 'Banking regulation for each seller ',
    'Reglement': 'Banking regulation',
    'Visites prévus par Commercial': 'Visits planned by Commercial',
    'KPI Commercial par Mois': 'KPI Commercial per month',
    'KPI Commercial': 'KPI Commercial',
    'Type': 'Type',
    'Global societe': 'Global society',
    'Début': 'Beginning',
    'Stat-vente P': 'Sales Statistics1',
    'Stat-vente C': 'Sales Statistics2',
    'Fin': 'End',
    'Veuillez enregistrer le fichier sous classeur Excel (*.Xlsx)': 'Please save the file as Excel workbook (*.Xlsx)',
    'Nbr Commandes': 'Order number',
    'NB/BL': 'Nb invoices',
    'NB/Jour': 'Nb days',
    'NB/Cmd': 'Nb order ',
    'NB/Commande': 'Nb order ',
    'NB/Avoir': 'Number of return invoices',
    'NB/Visites': 'Nb visits',
    'NB/Clients': 'Nb customers',
    'NB/Recouvrement': 'Nb recovery',
    'Tx succés': 'success rate',
    'Tx succès': 'success rate',
    'Tx Commande': 'order rate',
    'Tx respect': 'respect rate',
    'Client visité': 'Client visited',
    'Clients visitée': 'Client visited',
    'nbr Client/Vente': 'nbr Customer/Sale',
    'Nombre des Clients avec Vente': ' Number of Customers with Sale ',
    'Nombre des Clients': ' customer number',
    'Ajout Client': 'Add customer ',
    'Ajouter Client': 'Add customer ',
    'Modifier détails client': 'Edit customer detail',
    'KPI Global': 'Global KBI',
    'Nb Client': 'customer number',
    'Nb Clients': 'customer number',
    'R.tourne': 'Tour respect ',
    'Kilometrage': 'milenage',
    'Vente par produit': 'Sale by product',
    'Produit': 'Product',
    'Nom Client passager': 'Customer name',
    'qte': 'Qty',
    'Montant': 'amount',
    'Commandes TTC': 'orders all taxes included',
    'Ventes TTC': 'sales all taxes included',
    'Credit TTC': 'Credit all taxes included',
    'Prix TTC': 'Price all taxes included ',
    'Prix_TTC': 'Price',
    'Prix HT': 'price without tax',
    'Montant HT': 'amount without taxes',
    'Montant TTC': ' Amount all taxes included',
    'Choisir': 'choose',
    'Tous': 'all',
    'KPI Vendeur': 'KPI SELLER',
    'KPI Prevendeur': 'KPI PRESELLER',
    'Rapport Vente Par Region': 'Sales Report By Region',
    'Rapport DN Par Region': 'DN Report by region ',
    'export': 'export',
    'csv': 'CSV',
    'xlsx': 'xlsx',
    'Vente par Commercial': 'Sales per seller',
    'Anaylse vente Famille/Gamme': ' Sales analysis Family/Range ',
    'Chiffre': 'Number',
    'chiffre': 'Number',
    'Pourcentage': 'percentage',
    'Secteur': 'sector',
    'Adresse': 'Adress',
    'Code': 'Code',
    'Total': 'Total ',
    'ANALYSE TOURNEE': 'Tour analysis ',
    'LUNDI': 'Monday',
    'MARDI': ' Tuesday',
    'MERCREDI': ' wednesday',
    'JEUDI': 'Thursday',
    'VENDREDI': ' Friday',
    'SAMEDI': 'Saturday',
    'DIMANCHE': ' Sunday',

    'Lundi': 'Monday',
    'Mardi': ' Tuesday',
    'Mercredi': ' wednesday',
    'Jeudi': 'Thursday',
    'Vendredi': ' Friday',
    'Samedi': 'Saturday',
    'Dimanche': ' Sunday',

    'lundi': 'Monday',
    'mardi': ' Tuesday',
    'mercredi': ' wednesday',
    'jeudi': 'Thursday',
    'vendredi': ' Friday',
    'samedi': 'Saturday',
    'dimanche': ' Sunday',

    'lun': 'Monday',
    'mar': ' Tuesday',
    'mer': ' wednesday',
    'jeu': 'Thursday',
    'ven': ' Friday',
    'sam': 'Saturday',
    'dim': ' Sunday',

    'Nom': 'Name',
    'Magasin': 'Shop name',
    'Routing': 'Routing',
    'Date création': 'creation date',
    'Operation': 'operation',
    'KPI Commande par Gouvernorat': 'KPI Order by Governorate',
    'KPI Commande par Zone': 'KPI Order by Zone ',
    'Ecart': 'difference ',
    'nbr produit unique': 'number of unique products',
    'nbr produit vendu': 'nbr product sold',
    'quantite vendu': 'Qty sold',
    'N°': 'Number',
    'Numero': 'Number',
    'Tarif spécial': 'Special rate',
    'Mobile': 'Mobile number',
    'mobile': "mobile",
    "fixe": "phone number",
    'Fix': 'phone number ',
    'Tel': 'Phone',
    'Dernier visite': 'Last visit',
    'Date Dernier Visite': 'Date of last visit',
    'Observation': 'Note',
    'Encours': 'outstanding',
    'Date Credit': 'Credit Date ',
    'Traite': 'Draft',
    'Retenue Avoir': 'Retained Equity',
    'Retenue': 'discount',
    'Cause': 'Cause',
    'Commentaire': 'comment',
    'prévu prochaine Visite': 'Scheduled visits',
    'Nombre de PDV Planifié': 'Number of scheduled appointments ',
    'Nombre de Clients visités': 'Number of customers visited',
    'Nombre de Clients avec Ventes': 'Number of Customers with Sales ',
    'Contribution / Van %': 'Contribution of each truck',
    'Objectif CA TTC': 'Revenue target all taxes included ',
    'ttc / totalttc': 'Taxes Included / All Taxes Included',
    'Objectif Vs Réalisation %': 'Goal Vs Achievement %',
    'Chiffre d\'affaire Net': 'Net turnover ',
    'Taux de succés': 'Success rate ',
    'Taux de Visites': 'Visits rate',
    'Tx visites': 'Visits rate',
    'Tx visite': 'Visits rate',
    'Recrutment PDV': 'Nb POS',
    'CH TTC / nbre de client avec vente': 'Taxes Included / All Taxes Included',
    'Dropsize (DT)': 'All Taxes Included / Number of Customers Purchased',
    'Clients Visités': 'Customers Visited',
    'Nbr de licence achetée': 'Number of licenses purchased',
    'Nbr de licence installée': 'Number of installed licenses',

    //
    'Frequence': 'Frequency',
    'frequence': 'Frequency',
    'Chaque Semaine': 'every week',
    'Semaine Paire': 'even week',
    'Semaine Impaire': 'odd week',
    '1er Semaine': 'first week',
    '2eme Semaine': 'second week',
    '3eme Semaine': 'third week',
    '4eme Semaine': 'fourth week',

    'semaine': 'every week',
    'semainepaire': 'even week',
    'semaineimpaire': 'odd week',
    '1er_semaine': 'first week',
    '2eme_semaine': 'second week',
    '3eme_semaine': 'third week',
    '4eme_semaine': 'fourth week',

    'Cumul Credit': 'accumulated credit',
    // DN
    'Distribution numérique': 'digital distribution',
    'Intervale': 'interval',
    // clients
    'Categorie': 'category',
    'Catégorie': 'category',
    'Categorie Commercial': ' Seller category ',
    'Rechercher': 'search',
    'Nombre de Client par Activité': 'Number of customers by activity',
    'Nombre de Client par Catégorie': 'Number of customers by category',
    'Activité': 'Activity',
    'Route': 'road',
    'En cours': 'ongoing',
    'Date création': 'creation date',
    'Action': 'settings',
    'suivant': 'Next',
    'precedant': 'previous',
    'Echeance': 'Due date',
    'Modifier routes': 'edit road ',
    'Modifier Route': 'edit road ',
    'Modifier': 'Edit',
    'Supprimer': 'delete',
    'Prime': 'prime',
    'Réalisations': 'Achievements',
    'Ajouter Tournee': 'Add tour',
    'Nouvelle Route': 'Add new road',
    'hors tournee': 'Out of tour',
    'CIN': 'ID Card number',
    'Regime fiscale': 'tax system',
    'MF': 'tax identifier',
    'Adresse de facturation': 'Billing address',
    'Autorisation Credit': 'credit permission',
    'Catégorie commerciale': 'commercial category',
    'Plafond credit': 'cerdit limit',
    'Autorisation cheque': 'Check permission',
    'Plafond credit cheque': 'Credit cheque limit',
    'Plafond credit traite': 'Check and draft limit',
    'Autorisation traite': 'Draft permission',
    'Passager': 'passenger',
    'Accés Metrage': 'Meter Access',
    'Tarif': 'tariff',
    'tarif': 'Tariff',
    'tarif1': '1 Tariff',
    'tarif2': '2 Tariff',
    'Changement Prix': 'Change Price',
    'Code SMS': 'SMS Code',
    'Livraison': 'delivery',
    'Couleur': 'color',

    // routings
    'Matricule': 'Truck Number',
    'Matricule Interne': 'Internal registration number',
    'Camion': 'Truck',
    'Route Carte': 'Map road',
    'Suivi': 'monitoring',
    'Debut/Fin': 'Beginning/end',
    'Depot': 'deposit',
    'Dépots': 'deposit',
    'Moyenne': 'average',
    'Distributeur': 'distributer',
    'Nouveau Distributeur': 'New distributer',
    'valider': 'validate ',
    'Valider': 'validate ',
    'frequance': 'frequency',
    'facture': 'invoice',
    'Facture': 'invoice',
    'RemiseEspece': 'Discount Cash',
    'Remise Espece': 'Discount Cash',
    'Total TTC': 'Total All taxes included',
    'Total HT': 'Total without taxes   ',
    'Net a payer': 'net payable',
    'Net': 'Net',
    'Total': 'Total',
    'Non visité': 'No visits',
    'routes modifié avec succès': 'roads successfully changed',
    'Veuillez bien choisir une nouvelle route et au mois un client': 'Please choose a new road and at least one client',

    // ventes 
    'Bon de livraison': 'invoice',
    'Entete supprimées': 'Deleted files',
    'Total remise': 'Total discount',
    'Total net': 'Total net',
    'Total recouvrement': 'Total recovery',
   
    'Bon de Recouvrement': 'Debt Recovery slip',
    //tarification
    'Ajouter un Tarif': 'Add tariff',
    'Tarif': 'tariff',
    'P. TTC': 'Price Paid All taxes included',
    'P. HT': 'Price excluding tax',
    // OBJECTIFS
    'Vue globale': 'global view',
    'Ajouter nouveau objectif': 'Add new objective',
    'Ajouter des objectifs (Chiffre d\'affaire / visites)': '(Add objective ( Number Turnover / visit',

    'Libelle': 'Name',
    'Libelle Objectif': 'objective name',
    'Assortiment': 'assortment',

    'P.Vente': 'Selling Price',
    'P.Assortiment': 'Price assortment',

    'Ajouter un Objectif': 'Add objectiveا',
    'prix': 'Price',
    'Prix': 'Price',
    'Ajouter': 'Add',
    //fidelite
    'Ajouter Fidelite': 'Add Fidelity program ',
    'Fidelite': 'Fidelity program',
    'Equivalence': 'Equivalence',
    'Points': 'Fidelity points ',
    'Actif': 'Active',
    'Inactif': 'inactive',
    'Conditions': 'Conditions',
    'Ajouter une Condition gratuite': 'Add freebies conditions ',
    'Affectation des gammes à l\'utilisateur': 'Assignment of ranges to the user',
    'Position de': 'position',
    //fournisseurs
    'Nom et Prénom': 'Full name',
    'Nom': 'Family name',
    'Prénom': 'First name',
    'Grade': 'Grade',
    'Role': 'Role',
    'Metier': 'Profession',
    'Téléphone': 'Tel',
    'Actif': 'Active',
    'Actualisé': 'Synchronisation',
    'Ajouter un Fournisseur': 'Add supplier',
    'Fournisseurs': 'supplier',
    'Fournisseur': 'supplier',
    // pays
    'Pays': 'Country',
    'Longitude': 'Longitude ',
    'Latitude': 'Longitude ',
    //societe
    'Societe': 'Company',
    'Fiche Societe': '',
    'logo': 'Logo',
    'Matricule Fiscale': 'tax identifier',
    'Matricule fiscale': 'tax identifier',
    'telephone': 'Tel',
    'Telephone': 'Tel',
    'Fax': 'Fax',
    'email': 'Email',
    'Email': 'Email',
    'mot de passe': 'password',
    'mot de passe admin': 'Password Admin',
    'admin': 'Admin',
    'Admin': 'Admin',
    'prefix_bl': 'invoicePrefix:',
    'prefix_devis': 'pricing Prefix:',
    'prefix_commande': 'order Prefix:',
    'prefix_recouvrement': 'Recovery Prefix',
    'Pied_page': 'footer',
    'dernier page': 'Last page',
    'Voir Images': 'see picture',
    'Images': 'picture',
    'Non lu': 'unread',
    'Marquer comme non lu': 'Mark as unread',
    'Entete': 'top of the page',
    'page 1': 'top of the page',
    'Changement_prix': 'Change Price',
    'SMS Confirmation': 'validate SMS',
    'Message': 'Message',
    'Nouveau Societe': 'New company',
    'Image': 'picture',
    'Enregistrer': 'Register',
    // Camion_Prevendeur
    'Camion_Prevendeur': 'Truck',
    'O.Achat Avoir': 'Purchase commitment in the event of a return',
    'Synchronisation': 'Synchronisation',
    'Taux Avoir': 'Return rate ',
    'Gestion Client': 'customer management',
    'Modification Client': 'Edit customer',
    'Colisage': 'packing',
    'colisage': 'packing',
    'Sync Client': 'Sync customer',
    'Total CMD': 'Total orders',
    'Total Avoir': 'Total returns',
    'Total Recouvrement': 'Total recovery',
    'Total BL': 'Total invoices',
    'Sequence Client': 'Customer sequences ',
    'Modification remise': 'Edit discount',
    'Plafond Credit': 'credit limit',
    'Vente client avec credit': 'Customer sale with credit',
    'Paiement Esp': 'Cash payment',
    'Obligation Achat en cas d\'avoir': 'Purchase obligation in case of retur',
    'et' : 'and',
    'Modifier Camion': 'Edit Truck',
    // Equipements
    'Ajouter un Equipement': 'Add equipement',
    'Printer': 'Printer',
    'Etat': 'Conditions',
    'Adresse MAC': 'MAC ADRESS',
    'Derniere sync': 'Latest sync',
    "Dérnier Syncronisation": 'Latest sync',
        // Affectations

    'Affectation': 'assignment',
    'Affectations': 'assignment',
    'Affectation Prix': 'assignment prices',
    'Ajouter Affectation': 'Add assignment',
    'Ajouter une delegation': 'Add delegation',
    'Model xlsx': 'model xlsx',
    'Model': 'model',
    'nbr pdvs': 'Nb POS',
    // Delegations
    'delegation': 'delegation',
    'Delegation': 'delegation',
    'Delegations': 'delegation',
    'Gouvernorat': 'Governorate',
    'Localites': 'localities',
    'code postale': 'Postal code',
    'Ajouter une region': 'Add delegation',
    'Secteurs': 'sector',
    'Ajouter un Secteur': 'Add sector',
    'Chiffre par délegation': 'Number per delegation',
    // produits
    'Gratuites': 'freebies',
    'Pack': 'pack',
    'Promotion': 'discount',
    'Promotions': 'discount',
    'Télécharger le model': 'download model',
    'TXT prix': 'TXT Price',
    'Import mise a jour prix du produit format csv/txt (colone1=vide;colone2=produit_code,colone3=vide;colone4=prix_ttc)': 'Edit Price product ',
    'TXT': 'TXT',
    'Import produits Wavesoftsoft erp': 'Data import product Wavesoftsoft erp',
    'Import Nomadis Produits Format : (txt,csv,xls,xlsx)': 'Data import product form txt,csv,xls,xlsx',
    'Quantite Vendu': 'Qty sold',
    'Quantite Stock': 'Stock qty',
    'Activite': 'Activity',
    'Quantite min': 'Qty min',
    'Dimensions': 'Dimensions',
    'hauteur': 'height',
    'largeur': 'lenght',
    'profondeur': 'depth',
    'Code garantie': 'Guarantee code',
    'Nouveau produit': 'new product',
    'Unite': 'unit',
    'TVA': 'value added tax',
    'tva': 'value added tax',
    'Prix de vente Public': 'Public sale price',

    // gratuite
    'Gratuités': 'freebies',
    'Cumulable': 'cumulative',
    'Ajouter une gratuité': 'Add freebies',
    'Ajouter gratuité': 'Add freebies',
    'Groupe': 'group',
    'Affectation Clients': 'assignment customers',
    'Affectation Clients Gratuites': 'assignment freebies customers',
    'Affectation Clients Promotion': 'assignment discount customers',
    'Affectation Produits Promotion': 'assignment discount products',
    'A.Clients': 'Nb discount customers',
    'A.Produits': 'Nb discount products',

    'Ajouter une Affectation Client': 'Add discount for customer',
    'Ajouter une Affectation Produit': 'Add discount for product',
    'Ajouter une Affectation Gratuite': 'Add freebies product',

    'Statut': 'status',
    'Negociable': 'negotiable',

    //pack
    'Details': 'details',
    'Détails': 'details',
    'Ajouter Composition': 'Add composition',
    'Position': 'Position',
    'Localite': 'locality',
    'Numero Config': ' Config. Number',
    'Configuration': 'Configuration',
    'Ajouter une remise': 'Add discount',
    'Total Remise': 'Total discount',
    'Remise': 'discount',
    'Paiement Espece': 'cash payment',
    'Min': 'Min ',
    'Max': 'Max',
    'Max BL': 'Max invoice',
    'Max facture': 'Max invoice',
    'Max CMD': 'Max order',
    'Max Devis': 'Max devis',
    'Max avoir': 'Max return',
    'Max Recouvrement': 'Max recovery',
    'Code Response': 'Response code',
    'Voir Liste': 'see list',
    'Ajouter un Camion ou Prevendeur': 'Add Truck or preseller',
    'Details Pack': 'pack details ',
    // MOUVEMENT
    'Numero operation': 'operation number',
    'Fichier Excel': 'file excel ',
    //chargement 
    // 'Importation chargement Camion Format CSV/TXT avec separateur ;': 'export loads CSV/TXT',
    'Importation chargement Camion Format : xlsx/xls colonne 1: code colonne 2: qte et noublier pas de mettre les titres': 'Truck loading import Format: xlsx/xls column 1: code column 2: qty and do not forget to put the titles',

    'Importation Mouvement chargement Camion Format CSV/TXT avec separateur ; FoxPro': 'Import Truck loading movement CSV/TXT format with separator; FoxPro',
    'Importation Mouvement chargement Camion Format CSV/TXT avec separateur ; Wavesoft': 'Import Truck loading movement CSV/TXT format with separator; wavesoft',
    'Liste des demandes chargements': 'List of loading requests',
    'Liste des chargements': 'loads list',
    'Liste des Factures': 'invoices list',
    'Demande de chargements': 'loading request',
    'Demande de': 'request',
    'Code a barre': 'bar code',
    'Nouveau chargement': 'new load ',
    'Modification du chargement': 'Edit load',
    'Code chargement': 'load code',
    //equipement client
    'Numero de serie': 'serial number',
    'Refrigerateur': 'fridge',
    'Presentoire': 'stand',
    //notes
    'Liste des Notes': 'notes list',
    'Envoyé': 'Send',
    'Recu': 'receive',
    'Objet': 'object',
    'Fidelite du Client': 'Fidelity points of customer',
    'Total des points': 'total fidelity points',
    'Fidelite': 'fidelity points',
    //carburant
    'Carburant': 'fuel invoice ',
    'Ticket': ' Ticket ',
    'Code Commercial': ' distributer code',
    'Code client': 'customer code',
    'Cout de Visite par Commercial': 'Cost of Visit per Commercial',
    'Cout de Visite': 'Cost of visit',
    'Consommation': 'consumption',

    // stocks
    'Code en douane': 'customs code ',
    'Bon de Sortie N°': 'Exit voucher No.',
    'Destination': 'destination',
    'Produit en rupture': 'Product out of stock',
    'Valeur Stock': 'Stock value',
    'Valeur': 'Value',
    //todo
    'Todo': 'TODO',
    'Collaborateur': 'Collaborator',
    'Cible': 'Target ',
    'Status': 'Status ',
    'Titre': 'Title',
    'Description': 'description',
    'Date Before': 'Date before',
    'Avant': 'Before',
    'Apres': 'After',
    'Duree': 'duration',
    'PG': 'PG',
    'Priorite': 'Priority',
    'Cloture': 'closing',
    'Date after': 'Date after',
    'Image Obligatoire': 'picture obligatory',
    'Preuve': 'proof',
    'Ajouter une tache': 'Add task',
    'par defaut': 'default',

    //Rapport Visite
    'Rapport Visite': 'visit report',
    'Ajouter un Element': 'Add element',
    'Rapport Details': ' report details',
    'Stop': 'Stop',
    'Element': 'element',
    'Cloture': 'Closing',
    'Bloquer': 'block',
    //ACHAT
    'User': 'user',
    'Ajouter Commande': 'Add order',
    'Ajouter achat': 'Add purchase',
    //Devises
    'Devises': 'currency',
    'Ajouter devise': 'Add currency',
    'Taux': 'rate',
    //caisses
    'Recette': 'revenue',
    'Caisse Commercial': 'cash register',
    'Caisses': 'Fund ',
    'Recette Aujourd\'hui': 'today/s revenue',
    'Journée': 'today',
    //params promo
    'Message Promo': 'discount message ',
    'Parametage Mobile': 'Edit mobile',
    'Ligne': 'line',
    'Impression Recette': 'print ',
    'Modification Mobile Client': 'Edit mobile/s customer',
    'Export': 'Export',
    'Import': 'Import',
    'Télécharger la liste de clients': 'Download customer list',
    'Fermer': 'Close',
    'Ajouter une promo': 'Add discount',
    //message
    'Votre compte a été désactivée.': 'Your account has been deactivated please contact your admin to solve the problem',
    'Votre compte a été expirée.': 'expired account',
    'Erreur de verification de compte, veuillez contacter l\'admin.': 'Account verification error, please contact the admin.',

    // 404
    'Attention!': '!Warning',
    'Oups!': '!Warning',

    "vous n'avez pas la permission d'accéder à cette page": 'You do not have permission to access this page',

    //message swal
    'Commande ajoutée succés': 'Order added successfully',
    'Commande ajoutée avec succés': 'Order added successfully',
    'Attention': 'Attention',
    'erreur d\'insertion!': 'insertion error',
    'Voulez vous vraiment supprimer cette ligne!': 'Do you really want to delete this line!',
    'Ligne Supprimée avec succés': 'Row Deleted successfully',
    'Code Commande existe deja': 'Order code already exists',
    'produit existe deja!': 'Product already exists',
    'Commande n\'existe pas': 'Order does not exist',
    'ligne n\'existe pas!': 'Row does not exist!',
    'Supprimée': 'deleted',
    'activite modifiée avec succés': 'activity changed successfully',
    'Voulez vous vraiment supprimer cette activite!': 'Do you really want to delete this activity?!',
    'activite Supprimée avec Succès': 'activity deleted successfully',
    'Vous ne pouvez pas supprimer cette activite': 'You cannot delete this activity',
    'activite ajoutée avec Succès': 'activity added with success',
    'Veuillez bien remplir les deux champs date': 'Please fill in both date fields',
    'Veuillez bien remplir le champ date debut': 'Please fill in the start date field',
    'Veuillez bien remplir le champ date fin': 'Please fill in the end date field',
    'Suppression': 'removal',
    'code bl': 'invoice code',
    'Code BL': 'invoice code',
    'Oui, supprimer!': 'yes delete',
    'Non, Annuler!': 'No Cancel',
    'Succé': 'success',
    'Document supprimée avec succées': 'Document successfully deleted',
    'Error': 'Error',
    'Veuillez choisir un distributeur': 'Please choose distributor',
    'Veuillez choisir un depot': 'Please choose deposit',
    'Veuillez choisir un client': 'Please choose customer',
    'Veuillez bien remplir la liste des produits': "Please complete the product list",
     ' virgule': 'comma',
     ' Dinar': 'Dinar ',
     ' Cent': 'Hundred',
     ' Cents': 'Hundred ',
     'cent': 'Hundred',
     "cent ": ' Hundred',
     " cents": 'Hundred ',
     " cent ": ' Hundred',
     's et': ' and ',
     'mille ': 'thousand ',
     ' mille ': ' thousand ',
     ' million ': ' million ',
     " milliards ": 'billions',
     "cents": 'Hundred',
     "cent": 'Hundred',
     'milles': 'thousand ',
     'mille': 'thousand ',
     'millimes' : 'millime',
     'millime' : 'millime',
     /*
     "un": 'One',
     "deux": 'Two',
     "trois": 'Three',
     "quatre": 'Four',
     "cinq": 'Five',
     "six": 'Six',
     "sept": 'Seven',
     "huit": 'Eight',
     "neuf": 'Nine',
     "dix": 'Ten',
     "onze": 'eleven',
     "douze": 'twelveر',
     "treize": 'thirteen',
     "quatorze": 'fourteen',
     "quinze": 'fifteen',
     "seize": 'sixteen',
     "dix-sept": 'seventeen',
     "dix-huit": 'eighteen',
     "dix-neuf": 'nineteen',
     "vingt": 'twenty',
     "trente": 'thirty',
     "quarante": 'forty',
     "cinquante": 'fifty',
     "soixante": 'sixty',
     "quatre-vingt": 'eighty',
     "septante": 'seventy',
     "nonante": 'ninety',
     "huitante": 'eighty',
     
*/
    ' et ': ' and ',
    "Veuillez bien selectionner le client!": 'Please select the customer correctly!',
    "Veuillez choisir au moins une bl!": 'Please choose at least one invoice!',
    "Produit(s) gratuit(s)": 'free product',

    // authorization:
    'Authorisation': 'authorization',
    'Authorisation client': 'customer authorization',
    'Roles': 'Roles',
    'Ajouter un rôle': 'Add role',


    // typevisite:
    'Stock': 'Stock',
    'Recouvrement': 'Recovery',
    'Exclusive Concurent': 'Exclusive Competitor',
    'Concurent': 'Competitor',
    'Patron Absent': 'absent',
    'Ferme': 'Close',

    // consigne
    'Consignes': 'BOX',
    'Consigne': 'BOX',
    'Utilisateur': 'USER',
    'Entre': 'entered',
    'Sortie': 'Exit',
    'Solde': 'Sold',

    // typemouvements:
    'venteC': 'Sale',
    'chargement': 'loads',
    'Chargement': 'loads',
    'retour': 'return deposit',
    'Reprise': 'return deposit',
    'casse': 'breakage',
    'casseC': 'breakage',
    'DLC CASSE': 'breakage',
    'Casse': 'breakage',
    'Reservation': 'Reservation',
    'Sécurite': 'Security',
    'retourC': 'Return',
    'reenvoyer': 're-send loads',
    'inventaire': 'inventory',
    "Inventaire": "inventory",

    'Produits dispnibles pour le chargement': 'Products available for loading',
    'Tous les produits': 'All products',
    'Liste Factures': 'invoice list',
    'Liste Commandes': 'Order list',
    'Liste Credit pour demain': 'Credit list for tomorrow',
    'Date Échéance': 'Due date',
    'Liste des chèques pour demain': 'List of checks for tomorrow',
    'Code demande': 'loads code',

    'quantité chargements': 'loads quantity',
    'quantité retour': 'return quantity',
    'quantité vente réel': 'Real sale quantity',
    'quantité vente Net': 'Net sale quantity',
    'quantité vente facturé': 'sales quantity invoiced',
    'quantité non facturé': 'unbilled quantity',
    'Prix Unitaire': 'Price unit',
    'Total réel': 'Actual Total',
    'Total facturé': 'Total Billed',
    'vente réel': 'actual sale',
    'Vente Net': 'Sales Net ',
    'vente facturé': 'invoiced sale',
    'non facturé': 'Unbilled',
    'Prix Carton': 'Box price',
    'Carton': 'Box',
    'Cartons': 'Box',

    // alert
    'Voulez vous vraiment supprimer ce Client!': '!Do you really want to delete this customer',
    'Client Supprimée avec Succès': 'Customer Deleted Successfully',
    'Voulez vous vraiment supprimer cette route!': 'Do you really want to delete this road!',
    'route modifiée avec Succès': 'roads changed successfully',
    'a ete ajouté avec succé': 'Added successfully',
    'Voulez vous vraiment supprimer ce Tournée!': 'Do you really want to delete this Tour',
    'Tournée Supprimée avec Succès': 'Tour Successfully Deleted',
    'Route Supprimée avec Succès': 'Roads successfully deleted',
    'Tournée modifiée avec Succès': 'Tour modified with Successح',
    'Frequence modifiée avec Succès': 'Frequency modified with Success',
    'Voulez-vous vraiment Transformer cette commande en bon de livraison': 'Do you really want to turn this order into a delivery note',

    'Montant vente total': 'Total sale amount',
    'Montant vente à crédit': 'Credit sale amount',
    'A terme': 'In time',
    'Montant vente chèque': 'Cheque sale amount',
    'Montant recouvrement espece': 'Cash recovery amount',
    'Montant recouvrement': 'Recovery amount',
    'Montant recouvrement chéque': 'Cheque recovery amount',
    'Montant à payer': 'amount to pay',
    'Montant à payer (espece + cheque)': 'Amount to be paid (cash + cheque)',
    'Montant à payer par chéque': 'Amount to be paid per cheque',
    'Cumul écart caisse': 'Accumulated cash discrepancy',
    'Cumul écart caisse du jour': 'Cumulated cash difference of the day',
    'Ecart caisse du jour': 'Cash spread of the day',
    'Nombre de Facture': 'Number of invoices',
    'Tx de Retour': 'Return rate',
    'Liste des Recouvrements': 'Recovery list',
    'Caisse ajoutée avec Succès': 'Fund successfully added',
    'Veuillez bien remplir le champ commercial': 'Please fill in the commercial field',


    'Envoi vers android': 'Send to android',
    'piece': 'piece',
    'Correction': 'correction',
    'correction': 'correction',
    'BT Imprimante': 'printer bleutooth',
    'LIV': 'seller',
    'MAG': 'storekeeper',
    'Magasinier': 'storekeeper',
    'Bilan Journalier': 'Daily Balance',
    'PaiementAvoir': 'Payment by return',
    'cheque impaye': 'unpaid check',
    'Numero de la pièce': 'Number piece',
    'Chargement Type': 'loads type',
    'Convertir en Box': 'convert into box',
    'Convertir en piéce': 'convert into piece',
    'Transformer en bon de livraison': 'convert into invoice',
    'Cette Commande correspond a la bon de livraison N°:': ': This Order corresponds to the delivery note No.',
    'Ventes par gammes et familles': 'Sales by ranges and families',
    'Rapport de Tournée le': 'Tour report on',
    'de Monsieur': 'For Sir',
    'Suivi Commercial par mois': 'Commercial follow-up per month',
    'Obligation achat avoir': 'Obligation purchase credit',
    'Obligation photo visite': 'Obligation photo visit',
    'Sync Clients': 'Sync. Customer',
    'Max Client': 'Max customer',
    'Authorization Client': 'Authorization customer',
    'Authorization Modification Client': 'Authorization modify customer',
    'plafond Credit': 'credit limit',
    'Modification remise': 'Edit discount',
    'Blocage Commande': 'Block order',
    'version': 'Version',
    'Confirmation annulation Entete': 'Cancellation Confirmation Header',

    'Affectation users camion': 'Assignment of truck users',
    'parametrages': 'settings',
    'Parametrages': 'settings',
    'Type d\'opération': 'operation type',
    'NB/CMD': 'NB order',
    'NB/Visite': 'Nb visit',
    'Total Visite': ' Total visit',
    'encours': 'ongoing',
    'Encours Saisie': 'Amount due',
    'Plafond': 'Max',
    'Sens': 'sens',
    'Credit enCours': 'ongoing credit',
    'List Cheques': 'check list',
    'Suivi Commercial par client': 'Commercial follow-up by customer',
    'Suivi Clients': 'Customer follow-up',
    'Benefice': 'Benefice',
    'Objectif nombre de visite par jour': 'Target number of visits per day',
    'Objectif chiffre d affaire par jour': 'Target turnover per day',

    //Dashboard
    'Global societe': 'Global society',
    'admin': 'Admin',
    //bilan journalier
    'Stat-vente': 'Stat Selling',
    'Rapport stats/Produits': 'Stat/product report',
    'categorie': 'category',
    'Rapport stats/Commercial': 'Stats/Commercial Report',
    '% RC': 'commercial register rate',
    '% R.DLC': 'Expiration rate %',
    '% R.Casse': 'breakage %',
    'Vente Reel': 'real purchase',
    'Vente Facturé CL': 'invoiced sale',
    'Vente reel Client Passager': 'Real sale Customer Passenger',
    'CA': ' Turnover',
    'Id': 'Id',
    'Montant a payer': 'Amount to be paid',
    'Chéques': 'checks',
    'Chéque': 'checks',
    'Depense': 'Expenses',
    'gratuite': 'Freebies',
    'voulez vous vraiment supprimer cette caisse!': 'do you really want to delete this fund?',
    'Caisse': 'Box',
    'Oui': 'Yes',
    'Non': 'No',
    'Caisse supprimée avec Succès': 'fund successfully deleted',
    'Total': 'total',
    'Ecart': 'difference',
    'Versement Banque': 'Bank payment',
    'Ancien Crédit': 'Old Credit',
    'gratuite': 'freebies',
    'Caisse Modifié avec Succès': 'Box successfully modified',
    'Valider Caisse': 'Validate Box',
    'Cloture': 'close',
    'Succé': 'Success',
    'Caisse validé avec succé': 'Fund successfully validate',
    'Ok': 'Yes',
    'Solde Caisse': 'total Box',
    'Reference': 'reference',
    'Valeur DLC/CASSE': 'breakage / expired value',
    'Valeur DLC/CASSE (Android)': 'breakage / expired value (Android)',
    'Total espece': 'total cash',
    'Montant vente avoir': 'Amount of return',
    'Montant vente espece': 'Amount purchase cash',
    'Montant vente Ticket': 'Amount purchase ticket',
    'Vente Aujourd\'hui': 'today/s purchase',
    'Caisse Aujourd\'hui': 'today/s fund value',
    'veuillez saisir le montant total du recouvrement  espece, chéque, traite et ticket': 'please enter the total amount of collection cash, check, draft and ticket',
    'somme des encaissments (les opérations de ventes et de recouvrements) saisi par l utilisateur sur le telephone (espece + chèque + ticket)': 'sum of receipts (sales and collection operations) entered by the user on the telephone (cash + check + ticket)',
    'somme(chargement - reprise * prix unitaire) on tient pas en compte le retour DLC et casse': 'sum(loading - recovery * unit price) we do not take into account the DLC return and breakage',
    'total chèque vente et recouvrement': 'total check sale and recovery',
    'total espece vente et recouvrement': 'total cash sale and recovery',
    'valeur de la dlc et casse saisi par le magasinier': 'value of the dlc and breakage entered by the storekeeper ',
    'Paiement par Avoir': 'Payment with return',
    'Montant du Recouvrement': 'total recovery',

    
    //'Débit':
    'Note': 'Notes',
    //Dépot:
    'Séléctionnez un élément dans la liste': 'Select an item from the list',
    'Correction ajoutée avec succées': 'Correction added successfully',
    'Succés': 'Success',
    'Transformer Retour Conforme en Chargement': 'Transform Return Compliant into Loading',
    'Code Caisse': 'code Box',
    'Terminal': 'Tel',
    'Vente Facturé': 'Sale Invoiced',


    //Dashboard

    'Global societe': 'Global society ',
    'admin': 'admin',

    //bilan journalier

    'Stat-vente': 'Selling Stat',
    'Rapport stats/Produits': 'Stats/products Report ',
    'categorie': 'category',
    'Rapport stats/Commercial': 'Stats/commercial report',
    '% RC': 'return rate ',
    '% R.DLC': 'Expired %',
    "% R.Casse": "breakage %",
    "Vente Reel": "real purchase",
    "Vente Facturé CL": "Sale Invoiced CL ",
    "Vente reel Client Passager": "Real sale Customer Passenger ",
    "CA": "Turnover",
    "Id": "Id",
    "Montant a payer": "Amount to be paid",
    "Chéques": "checks",
    "Depense": "expenses",
    "gratuite": "freebies",
    "voulez vous vraiment supprimer cette caisse!": "?do you really want to delete this fund?",
    "Caisse": "Box",
    "Oui": "Yes",
    "Non": "No",
    "Caisse supprimée avec Succès": "Fund successfully deleted ",
    "Total": "total",
    "Ecart": "difference",
    "Versement Banque*": "Bank payment",
    "Ancien Crédit*": "Old credit",
    "gratuite*": "freebies",
    "Caisse Modifié avec Succès": "Fund successfully modified",
    "Valider Caisse": "Validate fund ",
    "Cloture": "Close ",
    "Succé": "Success",
    "Caisse validé avec succé": "Fund successfully validate",
    "Ok": "Yes",
    "Solde Caisse": "total Box ",
    "Reference": "reference",
    "Débit": "debit",
    "Note": "Note",
    "Dépot": "deposit",
    "Séléctionnez un élément dans la liste": "Select an item from the list",
    "Correction ajoutée avec succées": "Correction successfully added",
    "Succés": "Success",
    "Transformer Retour Conforme en Chargement": "Transform Return Compliant into Loading",
    "Code Caisse": "code Box ",
    "Terminal": "Tel",
    "Vente Facturé": "Sale Invoiced",
    "aucune caisse a modifier": "no fund to modify",

    //Chargement:
    "Demande": "loads demand",
    "Liste": "loads list",
    "quota": "quota",
    "Vider Quota": "Clear Quota",
    "Voulez vous vraiment supprimer tout les donnees de quota!": "Do you really want to delete all quota data!",
    "quota supprimé avec Succès.": "quota deleted successfully.",
    //ventes
    "Ajouter Bon de livraison": "Add invoice",
    "Paiement Traite": "Payment draft",
    "Ticket Cadeaux": "Gift ticket ",
    "Paiement en instance": "Payment pending",

    //gestion commandes 
    "Prepartion des Commandes": "Preparation of Orders ",
    "Suivi Commande et livraison en cours": "Order tracking and delivery in progress",
    "Preparation": "Preparation",
    "Chargé": "charged",
    "Sortie": "Exit",
    "Livré": "delivred",
    "Annulé": "Cancel",
    "Livrer": "delivered",
    "Annuler": "Cancel",
    "Préparation des Commandes": "Order preparation",
    "Commandes Non Traitées": "Unprocessed Orders",
    "N° Commande": "Number order ",
    "N° Cmd": "order Nb ",
    'N° Bl': 'invoice Nb',

    "carton": "Box",
    "Carton": "Box",
    "date Commande": "Order date",
    "date Livraison": "Delivery date",
    "Camion prévu pour la livraison": " Truck scheduled for delivery",
    "Camion de livraison": "Delivery truck",
    "Visites": "visit ",
    "Tickets et Remise": "Ticket and discount",
    "Tickets Cadeaux": "Gift ticket",
    "montant": "amount",
    "Remise Espece Commande": "Discount Cash Order ",
    "Succé": "success",
    "Remise ajouté avec succé": "Discount added successfully ",
    "Succé": "success",
    "ticket ajouté avec succé": "Ticket added successfully ",
    "Parametrage": "settings",
    "Activites": "Activity",
    "Categories": "categories",
    "Code": "code",
    "Libelle": "Name",
    "Action": "Activity",
    "Activité modifiée avec Succès": "Activity successfully modified ",
    "Voulez vous vraiment supprimer cette activité!": "Do you really want to delete this activity!",
    "Supprimer l'activité": "delete Activity ",
    "Ajouter activite": "Add Activity ",
    "Libelle": "Name",
    "Ajouter": "Add",
    "Catégorie modifiée avec Succès": "Category successfully modified",
    "Ajouter categorie": "Add category",
    "Tarification": "pricing",
    "Encours par Commercial par Client": "Outstandings per Saller per Customer",
    "Remise Client": "discount customer",
    "Correction Solde Client": "Customer Balance Correction",
    

    //Users
    "Connexion": "connected ",
    "Equipement modifié avec Succès": "Equipement successfully modified",
    "Recherche": "search",
    "Modification terminée avec succès": "Change completed successfully",
    "Voulez-vous vraiment supprimer cet élément?": " Are you sure you want to delete this item! ",
    "Autorisations rôles": "Authorization role",
    "Chargements": " loading ",
    "Modifier chargements": "Edit loads",
    "Demande de chargements": " loads request ",
    "Détails des demandes de chargements": " details loads request ",
    "Chargement type": "loads type ",
    "Consultation des commandes": " order detail ",
    "Banques": "bank",
    "Export Bl+Avoir": " loads invoice+return",
    "Ajout caisse": " Add fund ",
    "Clôture caisse": " close fund",
    "Modification caisse": " Edit fund ",
    "Suppression caisse": " delete fund ",
    "Quota": " Quota",
    "Ventes": " purchase ",
    "Liste Bls": " list of invoices",
    "Détails des bls": "invoice detail",
    "Recouvrements": "recovery",
    "Bl facturé": "invoice",
    "Liste users": " list of users ",
    "Ajout user": " Add user ",
    "Modification user": " Edit الuser ",
    "Suppression user": " delete user ",
    "Blocage user": " block user ",
    "Affectation gamme user": "User range assignment",
    "Listes des rôles": " list of roles ",
    "Ajout rôle": "Add role ",
    "Suppression rôle": "delete role ",
    "Liste clients": "list of customers ",
    "Modification client": "Edit customer",
    "Suppression client": "delete customer ",
    "Liste produits": " list of products ",
    "Ajout produit": " Add product ",
    "Modification produit": "Edit product ",
    "Suppression produit": " delete product ",
    "Suivi routings": " monitoring",
    "Zoning": "Zoning",
    "Merchandiser": "Merchandiser",
    "Modification terminée avec succès": "Change completed successfully",
    "Ajout effectué avec succès": "Added successfully",
    "Paramétrages": "settings",
    "Agent de recouvrement": "recovery agent",
    "Modification terminée avec succès": "Change completed successfully",
    "Voulez-vous vraiment supprimer cet élément?": "Are you sure you want to delete this item?",
    "Erreur vous ne pouvez pas supprimer cet élément!": "Error you cannot delete this item!",
    //"Attention!": " Warning",
    "Recherche": "search ",
    "Photo Bl Avoir": " picture invoice/ return ",
    "Retour sans chargement": " return without loads ",
    "Insertion Mobile Double": "  double Number Tel ",
    "Edition Avoir": " Edit return  ",
    "Fiche Article": " product form",
    "Accept et Refus total chargement": "Accept and Reject total loading",
    "Ajout commande hors client": "Add non-customer order",
    "Recouvrement Total": "total recovery",
    "Obligation GPS": "GPS obligation ",
    "Impression_recette": "prit revenue",
    "Modification_mobile": " Edit Number Tel ",
    "Retenue": "withholding tax",
    "Affichage liste prix": "list of prices ",
    "Affichage HT": "Duty-free display",
    "Quota commandes": "order quota ",
    "Modification terminée avec succès": "Modification completed successfully",
    "Voulez-vous vraiment supprimer cet élément?": "Do you really want to delete that?",
    "Ajout effectué avec succès": "Add completed successfully",
    "Suppression effectuée avec succès": "Deletion completed successfully",
    "Modification terminée avec succès": "Change completed successfully",
    "Confirmation annulation entête": "Header cancellation confirmation",
    "Registre de commerce": " Trade register",
    "Code postale": " postal code ",
    "Contrat": " contract ",
    "Commande stock": " stock orders ",
    "Ajout gratuités": "  Add freebies ",
    "Modification terminée avec succès": "Change completed successfully ",
    "Voulez-vous vraiment supprimer cet élément?": "Are you sure you want to delete this item?",
    "Voulez-vous vraiment supprimer cet élément?": "Are you sure you want to delete this item?",
    "Modification terminée avec succès": "Change completed successfully",
    "Ajout effectué avec succès": "Added successfully",
    "Suppression effectuée avec succès": "Deletion completed successfully ",
    "Disponible pour la livraison": "Available for delivery",
    "Modification terminée avec succès": "Change completed successfully",
    "Voulez-vous vraiment supprimer cet élément?": "Are you sure you want to delete this item?",
    "Ajout effectué avec succès": "Added successfully",
    "Suppression effectuée avec succès": "Deletion completed successfully ",
    "Equipements": "equipement",
    "Modification terminée avec succès": "Change completed successfully",
    "Voulez-vous vraiment supprimer cet élément?": "؟Are you sure you want to delete this item",
    "Ajout effectué avec succès": "Added successfully",
    "Suppression effectuée avec succès": "Deletion completed successfully",
    "Modification terminée avec succès": "Change completed successfully",
    "Voulez-vous vraiment supprimer cet élément?": "؟Are you sure you want to delete this item",
    "Ajout effectué avec succès": "Added successfully",
    "Suppression effectuée avec succès": "Deletion completed successfully",
    "Affectation terminée avec succès": "Assignment completed successfully",
    "Ajout effectué avec succès": "Added successfully",
    "Voulez-vous vraiment supprimer cet élément?": "Are you sure you want to delete this item?",
    "Suppression effectuée avec succès": "Deletion completed successfully",
    "Erreur vous ne pouvez pas supprimer cet élément! ": " Error you cannot delete this item!",
    "Blocage List Client": "block customer list",
    //produits 
    "Tarification": "pricing",
    "Ajout effectué avec succès": "Added successfully",
    "Voulez-vous vraiment supprimer cet élément?": "Are you sure you want to delete this item?",
    "Modification terminée avec succès": "Change completed successfully",
    "Suppression effectuée avec succès": "Deletion completed successfully",
    "prix_vente_public": "Public tariff",
    "prix_promotion": "Price discount",
    "Modification terminée avec succès": "Change completed successfully",
    "Prix promotionnel": "Price discount",
    "Prix d’achat HT": "Price purchase excluding taxes ",
    "Prix d’achat TTC": "Price purchase with taxes",
    "Soumis remise": "with discount",
    "Soumis Gratuite": "with freebies",
    "Soumis retenue": "withholding tax",
    "Hors vente": "off sale",
    "Ordre": "rank",
    "Fiche article": "product list",
    "Modification terminée avec succès": "Change completed successfully",
    "Erreur": "ERROR ",
    "Erreur vous ne pouvez pas supprimer cet élément!": "Error you cannot delete this item!",
    "Modification terminée avec succès": "Change completed successfully",
    "Voulez-vous vraiment supprimer cet élément?": "Are you sure you want to delete this item?",
    "Suppression effectuée avec succès": "Deletion completed successfully",
    "Ajout effectué avec succès": "Added successfully",
    "Suppression effectuée avec succès": "Deletion completed successfully",
    "Modification terminée avec succès": "Change completed successfully",
    "Suppression effectuée avec succès": "Deletion completed successfully",
    "Ajout effectué avec succès": "Added successfully",
    "Modification terminée avec succès": "Change completed successfully",
    "Modification terminée avec succès": "Change completed successfully",
    "Voulez-vous vraiment supprimer cet élément?": "Are you sure you want to delete this item?",
    "Suppression effectuée avec succès": "Deletion completed successfully",
    "Ajout effectué avec succès": "Added successfully",
    "Suppression effectuée avec succès": "Deletion completed successfully",
    "Ajout effectué avec succès": "Added successfully ",
    "Voulez-vous vraiment supprimer cet élément?": "Are you sure you want to delete this item?",
    "Suppression effectuée avec succès": "Deletion completed successfully",
    "Suppression effectuée avec succès": "Deletion completed successfully",
    "Modification terminée avec succès": "Change completed successfully",
    "Modification terminée avec succès": "Change completed successfull",
    "Groupe": "group",
    "Voulez-vous vraiment supprimer cet élément?": "Are you sure you want to delete this item?",
    "Suppression effectuée avec succès": "Deletion completed successfully",
    "Ajout effectué avec succès": "Added successfully  ",
    "Ajout effectué avec succès": "Added successfully  ",
    "Ajout effectué avec succès": "Added successfully  ",
    "Voulez-vous vraiment supprimer cet élément?": "Are you sure you want to delete this item?",
    "Ajout effectué avec succès": "Added successfully   ",
    "Voulez-vous vraiment supprimer cet élément?": "Are you sure you want to delete this item?",
    "Suppression effectuée avec succès": "Deletion completed successfully",
    "Modification terminée avec succès": "Change completed successfully",
    "Voulez-vous vraiment supprimer cet élément?": "Are you sure you want to delete this item?",
    "Ajout effectué avec succès": "Added successfully  ",
    "Suppression effectuée avec succès": "Deletion completed successfully",
    "Modification terminée avec succès": "Change completed successfully",
    "Voulez-vous vraiment supprimer cet élément?": "Are you sure you want to delete this item?",
    "Ajout effectué avec succès": "Added successfully   ",
    "Suppression effectuée avec succès": "Deletion completed successfully",
    "Charges": " expenses ",
    "Ajout d’une charge": "  Add expenses ",
    "Type de charge": "charge type ",
    "Commercial": "seller",
    "Livreur": "deliver",

    "Analyse charge": "Charges analysis",
    "Compte d’exploitation mensuel / van": "Monthly operating account / van",

    "CA brut": "Number Turnover",
    " Remises": " discount ",
    "CA net ": "Net Turnover ",
    "Achats consommés": "Consumed purchases",
    "Marge commercial brute": "Gross trading margin",
    "Dépenses de publicités & trades": "Advertising expenses & trades",
    "Marge commercial nette": "Net Gross trading margin",

    "Salaires et charges connexes": "Salaries and related charges ",
    "Frais de téléphone": "Telephone charges",
    "Charges pour force de vente": "Expenses for sales force",
    "Dotation aux amortissements": "Depreciation expense",

    "Charge d’intérêts": "Interest expense",
    "chargement ajoutée avec Succès": "loading successfully added",
    "Carburant": "fuel ",
    "Accepté": "accepted ",
    "Refusé": "rejected ",
    "Mixte": "mix",
    "Non traité": "Untreated",
    "Valeur Stock": "Stock value ",
    "Commission": "Commission",
    "Entretien": "maintenance",
    "Remise ajouté avec succé": "Discount successfully added",
    "ticket modifié avec succé": "Change completed successfull ",
    "Voulez vous vraiment bloquer ce client!": "Do you really want to block this client!",
    "Suppression avec succé":"Deletion completed successfully",
    "Entretien & réparations": "Maintenance & repairs",
    "Assurance véhicule": "Vehicle insurance",
    " Taxes sur les véhicules": "Vehicle taxes",
    "Frais visites auto": "Car visit fees",
    " Charges des matériaux roulants ": "charges",
    "Charges du matériel informatique": "IT equipment expenses",
    "Fournitures consommables pour amortissements": "Consumable supplies for depreciation",
    "Etat TCL": "TCL State",
    "Droit de timbre": " حق الطابع",
    "Autres charges": " other expenses ",
    "Total coût de distribution": "Total distribution cost",
    "Dépenses": "Expenses",
    "Résultat d’exploitation": "Operating result",
    "Nv objectifs": " new objective ",
    "N° object": " objective Number",
    "Mois": " Month ",
    "Semaine": " weeek  ",
    "Sous Famille": " sub family ",
    "Tx de vente": "purchase rate ",
    "DN stable": "DN stable",
    "Validation des objectifs": "validate objective",
    "Recrutement": "Recrutment",
    "Modification terminée avec succès": "Change completed successfully",
    "Voulez-vous vraiment supprimer cet élément?": "Are you sure you want to delete this item? ",
    "Suppression effectuée avec succès": "Deletion completed successfully",
    "Ajout effectué avec succès": "Added successfully  ",
    //STOCK : " الstock  " ,
    "Correction effectuée avec succès": "correction successfully",
    "Dépôt": " deposit ",
    "Ajout inventaire": " Add inventory",
    "Emplacement": "position",
    "Ecart": "الفارق ",
    "Ajout effectué avec succès": "Added successfully",
    "Il existe déjà un inventaire non clôturé": "There is already an open inventory",
    "Validation d’inventaire du:": "Inventory validation of",
    "Ajout effectué avec succès": " Added successfully",
    "Détails inventaire": "inventory details ",
    "Succès de validation": "Validation success",
    "Stock camion": "stock Truck  ",
    "Todolist": "TODO List",
    "Merchandising": "Merchandiding",
    "Rapport visites": "visit report ",
    "Réalisation": "realisation",
    "Réalisation en valeur": "value of realisation",
    "Réalisation en quantité": "Qty realisation",
    "Valeur": "value",
    "Quantité": "Quantity",
    "DN": "DN",
    "Recrutement": " Recrutment ",

    "Conventions": "conventions",
    "Produits": "product ",
    "Choisir gamme": " choose range  ",
    "Choisir famille": " choose family ",
    "Choisir sous famille": " choose sub-family ",
    "Choisir marque": " choose brand",
    "Intervalle": "interval",
    "Assortiment par catégorie client": "Assortment by customer category",
    "Veuillez sélectionner au moins une catégorie ": "Please select at least one category",
    "Ajout effectué avec succès": "Added successfully",
    "Recalculer": "re-calculate fund",
    "en dinars": "Dinar",
    "dinars": "Dinars",
    "Tout les camions": "All trucks",
    "voulez vous vraiment Valider les Commandes de": "do you really want to Validate the Orders of",
    "Voulez-vous vraiment annuler les commandes de camion": "Are you sure you want to cancel truck orders",
    "Veuillez selectionner le depot si vous voulez generer un bon de chargement": "Please select the depot if you want to generate a loading slip",
    "Valider Commande": "validate order",
    "Commandes validée avec Succès": "Orders successfully validated",

    "Consultation Commande": "Consultation Orders",
    "Date d'envoi": "Sending date",
    "Date Reception": "reception date",
    "Gajou Net": "Box",
    "Commande en Casier": "order in box",
    "Casier": "Box",

    "Tout les camions": "All trucks",
    "Gajou Reel": "Net box",
    "Bl(s) N°": "invoices",
    "veuillez bien vérifier les quantités supérieures à stock ou les quantités négatives": "please check the quantities higher than stock or the negative quantities",
    "Vente par Gamme": "Sale by Range",
    'Non Transformé en BL': 'Not Transformed into invoice',
    'Transformé en BL': 'invoices',
    'Modification de chargement': 'Edit loads',
    'Entrer votre mot de passe': 'Enter your password',
    'accepted': 'accepted',
    'refused': 'rejected',
    'chargement modifiée avec Succès': 'loading modified with success',
    "Vous n'êtes pas autorisé": 'You are not authorized',
    "Important": "Important",
    "Avant de changer le statut de accepted a refused, veuillez bien cliquer sur l'icone (purge) dans le téléphone correspendant": "Before changing the status from accepted to refused, please click on the icon (purge) in the corresponding phone",
    "Entrer le mot de passe de controlleur": "Enter controller password",
    "Controlleur" : 'controller',
    "Vérifier les quantités négatives": "Check for negative quantities",
    "Créer un mouvement de stock": "Create a stock movement",
    "Bl Non livré": 'invoices Not delivered',
    "Quantité en pièce": 'Qty in piece',
    "Quantité en carton": 'Qty in boxx',
    "Valorisé achat": "purchase value",
    "Valorisé vente": "Selling value",
    "Voulez-vous vraiment supprimer cet inventaire": "Are you sure you want to delete this inventory",
    "Inventaire supprimée avec Succès": "Inventory successfully deleted",
    "Valider l'inventaire": "validate inventory",
    "Inventaire validé avec succès": "Inventory validated successfully",
    "ré-envoyer": "re-send",
    "voulez vous vraiment ré-envoyer ce chargement!": "do you really want to send this upload again!",
    "Liste Retour": "list of returns",
    "Objectif modifiée avec Succès": "Objective modified with Success",
    "Interdit d'écraser un ancien intervalle d'objectif": "Forbidden to overwrite an old target interval",

    "role modifié avec Succès": "role changed successfully",
    "Voulez vous vraiment supprimer ce role!": "Do you really want to delete this role!",
    "role Supprimé avec Succès": "role Successfully Deleted",
    "Vous ne pouvez pas supprimer ce role": "You cannot delete this role",
    "role ajouté avec Succès": "role added with success",
    "responsable des ventes": "Admin purchase",
    "responsable_vente": "Admin purchase",

    "controle_chargement" : "Control loads",
    "Contrôleur de chargement": "loads controller",
    "controle_stock" : "control stock",
    "Contrôleur de stock": "Stock controller",
    "Liste des clients": "list of customers",
    "n'afficher que mes données" : "show only my data",
    "Pré commande": "orders",
    "Sans Paiement": "Without payment",
    
    "Vente comptoir": "Counter sale",
    "Modifié le": "Edit on",
    "Plafond client" : "customer ceiling",
    "Mouvements des clients" : "customers mouvement",
    "Mouvement clients" : "customers mouvemens",
    "commercial/chauffeur" : "driver / driver",
    "Sens" : "sens",
    "Fonction": "Function",
    "Credit GMS" : "GMS Credit",
    'Stock en piéce': "stock in piece",
    'Stock en casier': "stock by box",

    'Total quantite' : "Qty Total",
    "Total carton": "Total Box",
    "Heure de livraison": "Delivery time",
    "Heure de sortie du camion":"Truck exit time",
    "Poids": 'weight',
    'g': 'G',
    'Kg': 'KG',
    'T' : 'T',
    'Vos étes sur le point de supprimer le bl ainsi que leurs mouvements, visites et paiements': 'Your are about to delete the bl as well as their movements, visits and payments',
    'mot de passe invalide': 'invalid password',
    'erreur de chargement des données': 'data loading error',
    'En Attente' : 'pending',
    'Type de papier': 'Paper Type',
    'Crédit de cette BL': 'Credit of this invoice',

    'Crédit Client': 'customer credit',
    'Crédit Camion': 'Truck credit',    
    'Total Crédit Client': 'total customer credit',
    'Total Crédit Camion': 'total truck credit',
    'Plafond Client': 'ceiling customer',
    'Plafond Camion': 'ceiling Truck',
    'Crédit dépasse le plafond': 'Credit exceeds the limit',
    'Vente par mois/Année': 'Selling month/ year',
    'Valeur demandée': 'Requested value',
    'Valeur restée' :'Value remained',
    'façon de calcule' : "Calculate method",
    "se calcule en chargement et en bilan journalier (stat-camion)" : "is calculated by loading and daily balance (stat-truck)",
    "ne se calcule pas en chargement et en bilan journalier (stat-camion)" : "is not calculated by loading and daily balance sheet (stat-truck)",
    "ne se calcule pas en chargement et se calcule en bilan journalier (stat-camion)" : "is not calculated by loading and is calculated by daily balance sheet (stat-truck)",
    "Info User" : " info user",
    "Détails User" : "details user",
    "Parametrage User" : "settings user",
    "Info Dépot" : "info Truck",
    "Parametrage Dépot" : "settings Truck",
    "Affectation Gammes" : "assignment range",
    "Affectation Commercials" : "assignment user",
    "Affectation Sous Societes" : "assignment sub-society",
    "Blocage commande": "block invoices",
    "Vente par crédit": "Sale by credit",
    "Blocage liste clients": "Blocking customer list",
    "Délivré le" : "issued on",
    "Salaire Base": "Base Salary",
    "Type contrat": "Contract Type",
    "CDD": "CDD",
    "CDI": "CDI",
    "Gerant": "CEO",
    "Patente": "patent",
    "Date d'embauche": "Hiring date",
    "CNSS": "CNSS",
    "Nbr d'enfant": "Nb kids",
    "Permis": "driver's license",
    "Date de livrance": "Delivery date",
    "Remise facture avec paiement": "Invoice remittance with payment",
    "Choix Remise par commercial": "Choice Discount by saller",
    'Par_dates': 'by date',
    'Par dates': 'by date',
    'Affichage par commercial': 'Display by sales representative',
    'Affichage par sous société' : 'Display by sub-society',
    'Clients Non visitée' : 'Clients Non visitée',
    'Total Client' : 'total customers',
    'Crédit par commerciaux et clients' : 'Credit by salespeople and customers',
    'Crédit par clients' : 'Credit by customer',
    'Tournée Ajoutée avec succès' : 'Tour Added successfully',
    'Demande augmentation Plafond crédit exceptionnelle' : 'Request increase Exceptional credit ceiling',
    'Dernier visite commercial' : 'latest seller visit',
    'Dernier visite client' :' latest visit of customer',
    'Aucune image' : 'No picture',
    'Affecter soussociete' : 'assignment sub society',


    'Authorization roles': 'roles authorization',
    'Manuel Nomadis': 'Nomadis manual',
    'Power BI': 'POWER BI',
    'Notification': 'notification',
    'Parametrage commandes': 'settings orders',
    'Parametrage details commande': 'settings details orders ',
    'Echeances': 'Due date',
    'Nv Clients': 'new customer',
    'Demande avoir': 'return request',
    'Détails Avoir': 'details return',
    'Credit encours': 'ongoing credit ',
    'Visite Hors Client': 'visit not scheduled',
    'Modifier Chargements': 'Edit loads',
    'Demande Des chargements': 'loads request',
    'Detail Demande Chargement': 'details loads request',
    'Exporter bl+avoir': 'Export invoice+return',
    'Ajouter Caisse': 'Add fund ',
    'Cloture Caisse': 'close fund',
    'Modifier Caisse': 'Edit fund ',
    'Supprimer Caisse': 'delete fund ',
    'Détails Achat': 'details purchase ',
    'Détail Bl': 'invoice details',
    'Ajouter Bl': 'Add invoice',
    'Liste des commandes': 'list of orders',
    'Détail Commande': 'details orders',
    'Liste des factures': 'list of الinvoice',
    'Liste des recouvrements': 'list of recovery  ',
    'details recouvrement': 'details recovery  ',
    'Liste des BL Facturé': 'list of billed invoices',
    'Liste des Avoir': 'list of return',
    'Détail avoir': 'details of returns ',
    'Ajouter avoir': 'Add return ',
    'Liste Visites': 'list of visit ',
    'Liste User': 'list of user',
    'Liste Roles': 'list of roles',
    'Ajouter User': 'Add user',
    'Modifier User': 'Edit user',
    'Supprimer Client': 'delete customer ',
    'Supprimer User': 'delete user ',
    'Supprimer Role': ' delete role',
    'Bloquer User': ' block user',

    'Historiques Client': 'Customer History',
    'Tickets cadeaux & remise': 'Gift tickets and discount',
    'Equipement Client': 'equipement customers',
    'EncoursMarque Client': 'ongoing brand customer',
    'Mouvements Client': 'customers mouvement',
    'Parametrage Client': 'settings customers',
    'Liste Produits': 'list of product',
    'Ajouter Produit': 'Add product  ',
    'Modifier Produit': 'Edit product ',
    'Supprimer Produit': 'delete  product',
    'Suivi Routing': 'tour monitoring',
    'Zonning': 'delegation',

    'Signature client' : 'customer Signature',
    'Catalogue pdf': 'file',
    'Signature' : 'Signature',
    'Maps' : 'Map',
    'Map Vision' : 'Map vision',
    'commandes livrées non traité' : 'orders delivered unprocessed',
    'Voulez vous Rattraper ces commandes au camion' : 'Do you want to catch these orders at the truck',
    'Réinitialisation' : 'Reset',
    'voulez vous vraiment Réinitialiser la Commande N°' : 'do you really want to Reset Command N',
    'Tx Vente' : 'Selling rate',
    'Tx Visite': 'visit rate',
    'Clients sans position GPS' : 'Customer without GPS position',
    'Clients inactif' : 'inactive customers',
    'Clients bloqué':'block customers',
    'Clients sans tournée':'customers without tour',
    'inActif': 'inactive',
    'Non Bloquer' : 'Not blocked',
    'Bl annulé avec succès' : 'invoice canceled successfully',
    'Bl livré avec succès' :  'invoice successfully delivered' ,
    'Vous avez deja des paiements pour cette BL' :  'You already have payments for this' ,
    "passer a l'état livré" :  'change to delivered state' ,
    "Aucun paiement n'existe pour ce BL" :  'No payment exists for this invoice' ,
    "Pour passer le BL en crédit laisser tous les champs vides" : 'To pass the invoice in credit leave all the fields empty'  ,
    "Payer et passer a l'état livré" : 'Pay and move to delivered status' ,
    "Valider le paiement"  : 'Validate payment' ,
    "Mot de passe Obligatoire" : 'Password Required' ,
    "Avoirs non affecté": "Unallocated assets",
    "Après la validation il faut faire la synchronisation de téléphone" : "After the validation it is necessary to make the synchronization of telephone",
    "Client modifié avec succès" : 'Client modified successfully',
    "image Client"	 : 'Customer picture',
    "image Visite" : 'visit picture',
    "Vous êtes sur le point de" : 'You are about to',
    "Changer" : ' change',
    "par" : 'from',
    'Liste des retours' : 'list of return',
    "Produit hors stock ou quantité gratuite supérieur à quantité de stock" : "Product out of stock or free quantity greater than stock quantity",
    "Vous avez plusieurs produits gratuits" : "You have several freebies",
    "cliquez ici" : "Click here",
    "pour sélectionner" : "to select",
    "Avant d'imprimer vérifier la destination" : 'Before printing check the destination',
    "Valeur de promotion": "discount value",
    "Valeur de gratuites": " with freebies",
    "Vente sans promotion": "Without discount",
    "Historique de correction stock Depot": "Depot stock correction history",
    "Ancienne quantité"	: "Old qty",
    "Nouveau quantité" : "New qty",
    "Code Produit": "product code",
    "Augmentation" : "increase",
    "Diminution" : "decrease",
    "Clients programmés non visité": "Unvisited Scheduled Clients",
    "clients non visité": "customers not visited",
    "Date prévu pour la livraison" : "Scheduled date for delivery",
    "Transformer en bon de livraison": "Convert to delivery note",
    "Choix imprimante": "Choose printer",
    "Afficher la liste des produits": "Show product list",
    "Numero piece": "sheet number",
    "avec timbre": "with stamp",
    "Valeur timbre": "Stamp value ",
    "Choix Catégorie client": "Choose customer category ",
    "Valider la transformation de cette commande en Bon de livraison" : "Validate the transformation of this order into a delivery note",
    "Voulez vous envoyer vers le terminal du livreur": "Do you want to send to the delivery person's terminal",
    "Date prévu de livraison": "Expected delivery date",
    "Voulez-vous vraiment Annuler cette commande": "Are you sure you want to Cancel this order",
    "Dépots sans tournees" : "Depots without tours",
    "Global societe" : " Global society",
    "Tx.D" : "Distribution rate",
    "DN.G" : "Global DN ",
    "Credit en cours" : "ongoing credit",
    "Kilometrage" :"Mileage",
    "Fermer Recherche Client" : "Close Customer Search",
      "Obligation Photo" : " picture obligation",
      "Obligation GPS" : " obligation GPS ",
      "Valeur Tolerance" : "Tolerance value ",
      "Ajout Client dans tournée automatique" : "Add Customer to automatic route",
      "Plafond demande de chargement" : "Loading request ceiling",
   

    "_rapport1_" : '*Report 1: Total commercial sale by product (figure/qty/box) at a time interval limited by a start and end date, payment methods (Sp, Chq, credit), the rate of sale and success, the number of customers visited and the number of new customers recruited.',
    "_rapport2_" : '*Report 2: Total sales for each salesperson (number/qty/box) at a time interval limited by a start and end date, the payment methods (Sp, CHQ, credit), the sales and success rate, the number of customers visited and the number of new customers recruited.',
    "_rapport3_" : '',
    "_rapport4_" : '*Report 4: Commercial KPI (sales, credit, collection, nbr BL, nbr visits...) per day, per month and per year, details of rounds (start time, end time, work time and time off work... ) daily. unvisited scheduled customers and sale by products' ,
    "_rapport5_" : '*Report 5: Sales by brand, range and family ',
    "_rapport6_" : '*Report 6: Sales and DN by region',
    "_rapport7_" : '*Report 7 : Periodical sale (start date, end date) by brand, family, sub-family, range, product and supplier (box, qty, value).',
    "_rapport8_" : '*Report 8: Periodic sale: per month, year of a family per commercial.',
    "_rapport9_" : '*Report 9: Family sale (qty, figure, percentage) by product range.',
    "_rapport10_" : '*Report 10: Customer Sales by Date.',
    "_rapport11_" : '*Report 11: Percentage of sales of each salesperson for each product line.',
    "_rapport12_" : '*Report 12: Sale by sub-family by customer.',
    "_rapport13_" : '*Report 13: DN by product, the percentage is calculated as follows: (number of products sold per month / number of customers per month) ',
    "_rapport14_" : '*Report 14: DN by product, the percentage is calculated as follows: (nbr of product sold per month / nbr customers per month) by region',
    "_rapport15_" : '*Report 15: (success rate/respect rate) for each salesperson ',
    "_rapport16_" : '',
    "_rapport17_" : '*Report 17: Commercial sale: displays the sales total indicating the start and end date.',
    "_rapport18_" : '*Report 18: Commercial sale: (number of visits, number of Bl, number of paid and unpaid BL, number of assets, sales, receipts, collections) ...',
    "_rapport19_" : '*Report 19: Order KPI by governorate.',
    "_rapport20_" : '*Report 20: Sales figure by area.',
    "_rapport21_" : '*Report 21: Total sale, total number of BLs, total number of visits and total qty sold and classification in ascending order according to the amount of sale by (range, family, sub-family, brand and product)',
    "_rapport22_" : '*Report 22: Sales by customer (range, family, sub-family, brand) by sales representative and date.',
    "_rapport23_" : '*Report 23: Credit by sales rep showing customer details, credit date and route.',
    "_rapport24_" : '*Report 24: Consultation of customer credits',
    "_rapport25_" : '*Report 25: Credit by sales rep showing customer details, credit date and total.',
    "_rapport26_" : '',
    "_rapport27_" : '',
    "_rapport28_" : '',
    "_rapport29_" : '',
    "_rapport30_" : '',
    "_rapport31_" : '',
    "_rapport32_" : '',
    "_rapport33_" : '',
    "_rapport34_" : '',
    "_rapport35_" : '',
    "_rapport36_" : '',
    "_rapport37_" : '',
    "_rapport38_" : '',
    "_rapport39_" : '',
    "_rapport40_" : '',

    
    
};
