function clientController(AppSettings, DatatableLang, $state, $scope, WS,
    $window, $translate, $compile, Excel, $timeout, $anchorScroll) {
    'ngInject';
    const vm = this;
    vm.clients = {};
    vm.files = [];
    //vm.center = "32.651619, -6.566738"; 
    //vm.center = "35.0000947,9.5008767";
    //vm.center = AppSettings.mapsalgerie;
    vm.keymaps = AppSettings.keymaps;
    //vm.creditclient = false;
    vm.commercial = '';
    vm.cl_encours = {};
    vm.client_code = '';
    vm.activite_code = '';
    vm.categoriecommercial_code = '',
        vm.gouvernorat_code = '';
    vm.region_code = '';
    vm.zone_code = '';
    vm.delegation_code = '';
    vm.routing_code = '';
    vm.specialite = '';
    vm.user_code = '';
    vm.credit = '';
    vm.etat = '';
    vm.adv_search = '';
    vm.year = moment().format("YYYY");
    vm.year_chiffre = moment().format("YYYY");

    vm.type_gamme = 'm';
    vm.month_names = [
        $translate.instant("Janvier"), $translate.instant("Février"), $translate.instant("Mars"),
        $translate.instant("Avril"), $translate.instant("Mai"), $translate.instant("Juin"),
        $translate.instant("Juillet"), $translate.instant("Août"), $translate.instant("Septembre"),
        $translate.instant("Octobre"), $translate.instant("Novembre"), $translate.instant("Décembre"),
    ];
    

    $($window).scroll(function (e) {
        var $el = $('#container-Client');
        if ($('#stopMapOn')) {
            var stopOn = $('#stopMapOn').offset().top;

            var isPositionFixed = ($el.css('position') == 'fixed');
            if ($(this).scrollTop() > 200 && $(this).scrollTop() < stopOn && !isPositionFixed) {
                $el.css({ 'position': 'fixed', 'top': '0px' });
            }
            if (($(this).scrollTop() < 200 || $(this).scrollTop() >= stopOn)) {
                $el.css({ 'position': 'static', 'top': '0px' });
            }
        }

    });


    $(document).ready(function () {

        $(".datepicker-dashb").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.year = date;
                $scope.$apply();
                //console.log(vm.month);
            }
        });
    })


    vm.reinitDate = () => {
        $(document).ready(function () {

            $(".datepicker-dashb").datepicker({
                autoClose: true,
                onSelect: function (date) {
                    vm.year = date;
                    $scope.$apply();
                    //console.log(vm.month);
                }
            });

            $(".year_chiffre").datepicker({
                autoClose: true,
                onSelect: function (date) {
                    vm.year_chiffre = date;
                    $scope.$apply();
                    //console.log(vm.month);
                }
            });
        })
    }


    vm.getcentermaps = function () {
        WS.getScache('pays').then(function (response) {
            vm.getpays = response.data;
            vm.center = vm.getpays.latitude + ', ' + vm.getpays.longitude;
            vm.zoom = AppSettings.zoomalgerie;
            $scope.$apply();
        }, function (error) {
            console.log(error);
        });
    };

    vm.generateCAB = (code_a_barre) => {
        WS.getData('clients/generateCAB', { code_a_barre })
            .then((response) => {

                $('.block_cab').empty();
                $('.block_cab').append(response.data.data);
                vm.printElement('print_block_cab');
            }, (error) => {
                swal(
                    $translate.instant('Oups'),
                    $translate.instant('Format incorrect'),
                    'warning'
                )
            });
    };

    vm.printElement = function (block_id) {

        var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`<html>
                                    <head>
                                    <title> ${document.title} </title>
                                `);
        mywindow.document.write('</head><body >');
        mywindow.document.write(document.getElementById(block_id).innerHTML);
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    }


    vm.cloturer = function () {
        swal({
            title: $translate.instant('Êtes-vous sûr ?'),
            text: "",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui, cloturer!'),
            cancelButtonText: $translate.instant('Non, Annuler!'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: true
        }).then(function () {

            WS.get('mouvement_clients/cloturer')
                .then(function (response) {
                    swal(
                        $translate.instant('Succé'),
                        $translate.instant('Cloture faite avec succées'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                })
                .then(null, function (error) {
                    console.log(error);
                });
        });


    };

    /*vm.selectMaps = function(){
        WS.get('clients/selectMaps').then(function(response){
        vm.centerpos = response.data.center.center;
        $scope.$apply();
    },function(error){
        console.log(error);
    });
    };*/
    //show custum pages in pagination : pagination inside pagination
    vm.checkpaginate = function (current, page, lastpage) {
        if (page > current + 3 || page < current - 3) {
            return false;
        } else {
            return true;
        }
    }
    //vider la recherche
    vm.initsearch = function (searchbox) {
        if (searchbox == '') {
            vm.get();
        }
    }


    vm.update_credit_searchable = () => {
        if (vm.credit != '') {
            vm.creditclient = true;
        } else {
            vm.creditclient = false;
        }
        vm.get(1, 10);
    }
    vm.showallcredit = function () {
        if (vm.creditclient == true) {
            vm.credit = (vm.credit) ? vm.credit : "ASC_CREDIT";
        } else {
            vm.credit = "";
        }

        vm.get(1, 10);

    };

    vm.initGetClient = () => {
        vm.activite_code = '';
        vm.categoriecommercial_code = '',
            vm.gouvernorat_code = '';
        vm.region_code = '';
        vm.zone_code = '';
        vm.delegation_code = '';
        vm.routing_code = '';
        vm.user_code = '';
        vm.credit = '';
        vm.etat = '';
        vm.specialite = '';
        vm.adv_search = ''
        vm.type_notif = '';
        vm.check_out_gps = false;
        vm.check_inactif = false;
        vm.check_bloque = false;
        vm.check_hors_tourne = false;
        vm.get();
    };

    vm.get = function (page = 1, perpage = 15) {
        //alert(vm.creditclient)
        var data = {
            page, perpage,
            categoriecommercial_code: vm.categoriecommercial_code,

            activite_code: vm.activite_code,
            zone_code: vm.zone_code,
            region_code: vm.region_code,
            gouvernorat_code: vm.gouvernorat_code,
            delegation_code: vm.delegation_code,
            routing_code: vm.routing_code,
            user_code: vm.user_code,
            specialite: vm.specialite,
            adv_search: vm.adv_search,
            etat: vm.etat,
            credit: vm.credit,
            type_notif: vm.type_notif,
            date_debut: vm.date_debut,
            date_fin: vm.date_fin
        };

        WS.getData('clients/paginate', data)
            .then(function (response) {
                vm.clients = response.data.clients.data;
                vm.current_page = response.data.clients.current_page;
                vm.previous = vm.current_page - 1;
                vm.next = vm.current_page + 1;
                vm.from = response.data.clients.form;
                vm.last_page = response.data.clients.last_page;
                vm.next_page_url = response.data.clients.next_page_url;
                vm.per_page = Number(response.data.clients.per_page);
                vm.prev_page_url = response.data.clients.prev_page_url;
                vm.to = response.data.clients.to;
                vm.total = response.data.clients.total;
                vm.paginate = [];
                for (var i = 1; i <= vm.last_page; i++) {
                    vm.paginate.push(i);
                }

                vm.nbr_client_out_gps = response.data.nbr_client_out_gps;
                vm.nbr_client_inactif = response.data.nbr_client_inactif;
                vm.nbr_client_bloque = response.data.nbr_client_bloque;
                vm.nbr_client_hors_tourne = response.data.nbr_client_hors_tourne;

                /**
                 * MAPS
                 */
                vm.selectmapsclients = angular.copy(vm.clients);
                vm.centerroutes = angular.copy(response.data.center.latitude + ', ' + response.data.center.longitude);
                vm.zoomRouting = 7;

                $('#container-Client').empty();
                $('#container-Client').html('<div style="width: 100%; height: 600px" id="mapContainerClients"></div>');

                var mymap = L.map('mapContainerClients').setView([response.data.center.latitude, response.data.center.longitude], vm.zoomRouting);


                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
                    foo: 'bar',
                    attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
                })
                    .addTo(mymap);


                vm.selectmapsclients.forEach(function (element, index) {
                    // Creates a red marker with the coffee icon
                    var redMarker = L.ExtraMarkers.icon({
                        icon: 'fa-home',
                        markerColor: (parseFloat(element.encours)) < 0 ? 'red' : 'blue',
                        shape: 'circle', //'circle', 'square', 'star', or 'penta'
                        prefix: 'fa'
                    });

                    if (element.latitude && element.longitude) {
                        var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);
                        var info = $compile(`
                                            <div id="bodyContent" style="width=300px;">
                                                <p class="text-center">
                                                    <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                        Modifier
                                                    </a>
                                                    <b> | </b>
                                                    <a ui-sref="app.clients.historiques({code : ${element.code}})" target="_blank">
                                                    Historique
                                                    </a>
                                                </p>
                                                <b>Client</b> : ${element.nom} ${element.prenom}</a></br>
                                                <b>Code</b> : ${element.code}</a></br>
                                                <b>Code_a_barre</b> : ${element.code_a_barre}</br>
                                                <b>Route</b> : ${element.routing_code}</br>
                                                <b>Mobile</b> : ${element.mobile}</br>
                                                <b>Commercial</b> : ${element.user_l}</br>
                                                </div>
                                            `)($scope);
                        var popup = L.popup({
                            maxWidth: 600,
                            minWidth: 200
                        }).setContent(info[0])
                        marker.bindPopup(popup);
                    }


                });

                $scope.$apply();

            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.check_out_gps = false;
    vm.check_inactif = false;
    vm.check_bloque = false;
    vm.check_hors_tourne = false;
    vm.type_notif = '';
    vm.etat = '';
    vm.clickNotif = (value) => {
        vm.type_notif = '';
        vm.etat = '';
        if (value == 'check_out_gps') {
            vm.check_out_gps = !vm.check_out_gps;
            vm.check_inactif = false;
            vm.check_bloque = false;
            vm.check_hors_tourne = false;

            if (vm.check_out_gps == true) {
                vm.type_notif = value;
            }
        }
        if (value == 'check_inactif') {
            vm.check_inactif = !vm.check_inactif;
            vm.check_out_gps = false;
            vm.check_bloque = false;
            vm.check_hors_tourne = false;

            if (vm.check_inactif == true) {
                vm.type_notif = value;
                vm.etat = 'inActif';
            } else {
                vm.etat = '';
            }
        }
        if (value == 'check_bloque') {

            vm.check_bloque = !vm.check_bloque;
            vm.check_out_gps = false;
            vm.check_inactif = false;
            vm.check_hors_tourne = false;

            if (vm.check_bloque == true) {
                vm.type_notif = value;
                vm.etat = 'Bloquer';
            } else {
                vm.etat = '';
            }
        }
        if (value == 'check_hors_tourne') {
            vm.check_hors_tourne = !vm.check_hors_tourne;
            vm.check_out_gps = false;
            vm.check_inactif = false;
            vm.check_bloque = false;

            if (vm.check_hors_tourne == true) {
                vm.type_notif = value;
            }
        }

        vm.get();
    }


    vm.exportToExcel = function () {
        var data = {
            categoriecommercial_code: vm.categoriecommercial_code,
            activite_code: vm.activite_code,
            specialite: vm.specialite,
            gouvernorat_code: vm.gouvernorat_code,
            delegation_code: vm.delegation_code,
            region_code: vm.region_code,
            zone_code: vm.zone_code,
            routing_code: vm.routing_code,
            user_code: vm.user_code,
            adv_search: vm.adv_search,
            etat: vm.etat,
            credit: vm.credit,
            type_notif: vm.type_notif,
            date_debut: vm.date_debut,
            date_fin: vm.date_fin
        };


        var filename = 'Clients-' + moment().format("DD-MM-YYYY_HH:mm:ss");
        WS.getData('clients/export', data).then(
            function (response) {
                alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsv, response.data]);
                alasql('SELECT * INTO CSV("' + filename + '.csv",?) FROM ?', [stylecsv, response.data]);
            },
            function (error) {
                console.log(error);
            }
        );
    };


    var stylecsv = {
        sheetid: 'clients - ' + moment().format("DD-MM-YYYY HH:mm:ss"),
        headers: true,
        /*  caption: {
             title: 'clients - ' + moment().format("DD-MM-YYYY HH:mm:ss"),
             style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
         }, */
        //style:'background:#fff',
        column: {
            style: 'font-size:20px;background:#d1d1d1;'
        },
        columns: [
            { columnid: 'id' },
            { columnid: 'code', title: 'Code' },
            { columnid: 'code_a_barre', title: 'Code_a_barre' },
            { columnid: 'activite', title: 'Activite' },
            { columnid: 'user', title: 'User' },
            { columnid: 'client', title: 'Client' },
            { columnid: 'description', title: 'Description' },
            { columnid: 'tarification', title: 'Tarification' },
            { columnid: 'magasin', title: 'Magasin' },
            { columnid: 'code_tva', title: 'Code_tva' },
            { columnid: 'code_erp', title: 'Code_erp' },
            { columnid: 'tax', title: 'Tax' },
            { columnid: 'adresse_facturation', title: 'Adresse_facturation' },
            { columnid: 'adresse_livraison', title: 'Adresse_livraison' },
            { columnid: 'statut', title: 'Statut' },
            { columnid: 'email', title: 'Email' },
            { columnid: 'fixe', title: 'Fixe' },
            { columnid: 'mobile', title: 'Mobile' },
            { columnid: 'latitude', title: 'Latitude' },
            { columnid: 'longitude', title: 'Longitude' },
            { columnid: 'routing_code', title: 'Route' },
            { columnid: 'rib', title: 'Rib' },
            { columnid: 'isactif', title: 'Isactif' },
            { columnid: 'autorisation', title: 'Autorisation' },
            { columnid: 'plafond_credit', title: 'Plafond_credit' },
            { columnid: 'autorisation_cheque', title: 'Autorisation_cheque' },
            { columnid: 'plafond_credit_cheque', title: 'Plafond_credit_cheque' },
            { columnid: 'autorisation_traite', title: 'Autorisation_traite' },
            { columnid: 'autorisation_traite', title: 'Autorisation_traite' },
            { columnid: 'rc', title: 'Rc' },
            { columnid: 'cin', title: 'CIN' },
            { columnid: 'localite', title: 'Localite' },
            { columnid: 'delegation', title: 'Delegation' },
            { columnid: 'region', title: 'Region' },
            { columnid: 'gouvernorat', title: 'Gouvernorat' },
            { columnid: 'secteur', title: 'Secteur' },
            { columnid: 'zone', title: 'Zone' },
            { columnid: 'categoriecommercial', title: 'Categoriecommercial' },
            { columnid: 'categorie_statistique', title: 'Categorie Statistique' },
            { columnid: 'regime_fiscale', title: 'Regime_fiscale' },
            { columnid: 'matricule_fiscale', title: 'Matricule_fiscale' },
            { columnid: 'sp', title: 'Equipement' },
            { columnid: 'encours', title: 'Encours' },
            { columnid: 'last_visite', title: 'Derniere_visite' },
            { columnid: 'created_at', title: 'Created_at' },
            { columnid: 'updated_at', title: 'Updated_at' }
        ],
        row: {
            style: function (sheet, row, rowidx) {
                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
        }
    };

    vm.search = function (perpage, search, page = 1) {

        if (search == '') { vm.get(); return; }
        if (search != '' || search != null || search != undefined) {
            WS.get('clients/search/' + perpage + '/' + search, null, page).then(
                function (response) {
                    vm.type = "search";
                    vm.query = response.data.search;
                    vm.clients = response.data.data.data;
                    vm.current_page = response.data.data.current_page;
                    vm.previous = vm.current_page - 1;
                    vm.next = vm.current_page + 1;
                    vm.from = response.data.data.form;
                    vm.last_page = response.data.data.last_page;
                    vm.next_page_url = response.data.data.next_page_url;
                    vm.per_page = Number(response.data.data.per_page);
                    vm.prev_page_url = response.data.data.prev_page_url;
                    vm.to = response.data.data.to;
                    vm.total = response.data.data.total;
                    vm.paginate = [];
                    for (var i = 1; i <= vm.last_page; i++) {
                        vm.paginate.push(i);
                    }
                    vm.getcentermaps();
                    $scope.$apply();
                },
                function (error) {
                    console.log(error);
                })
        } else {
            vm.get();
        }
    };

    vm.getSumByActivite = function () {
        WS.get('clients/getSumByActivite')
            .then(function (response) {
                if (response.status == 200) {
                    var chart = AmCharts.makeChart("getSumByActivite", {
                        "type": "pie",
                        "theme": "light",
                        "dataProvider": response.data,
                        "valueField": "sum",
                        "titleField": "activite",
                        "outlineAlpha": 0.4,
                        "depth3D": 15,
                        "balloonText": "[[title]]<br><span style='font-size:14px'><b>[[sum]]</b> ([[percents]]%)</span>",
                        "angle": 30,
                        "export": {
                            "enabled": true
                        }
                    });
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.getSumByCategory = function () {
        WS.get('clients/getSumByCategory')
            .then(function (response) {
                if (response.status == 200) {
                    var chart = AmCharts.makeChart("getSumByCategory", {
                        "type": "pie",
                        "theme": "light",
                        "dataProvider": response.data,
                        "valueField": "sum",
                        "titleField": "category",
                        "outlineAlpha": 0.4,
                        "depth3D": 15,
                        "balloonText": "[[title]]<br><span style='font-size:14px'><b>[[sum]]</b> ([[percents]]%)</span>",
                        "angle": 30,
                        "export": {
                            "enabled": true
                        }
                    });
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.count_sp = function () {
        WS.getScache('clients/count_sp')
            .then(function (response) {
                if (response.status == 200) {
                    vm.count_spv = response.data[0].sp;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };




    vm.findClientByMaps = function (id, soldes) {
        if (soldes == 0 || soldes == '') {
            /*background-color: #baf7e3 !important;   ver*/
            angular.element(document.getElementById('client-' + id)).addClass('markerGreen');
            $timeout(function () {
                angular.element(document.getElementById('client-' + id)).removeClass('markerGreen');
                $timeout(function () {
                    angular.element(document.getElementById('client-' + id)).addClass('markerGreen');
                    $timeout(function () {
                        angular.element(document.getElementById('client-' + id)).removeClass('markerGreen');

                    }, 300);
                }, 300);
            }, 200);
        } else {
            /*background-color: #f5b2b9 !important; red */
            angular.element(document.getElementById('client-' + id)).addClass('markerRed');
            $timeout(function () {
                angular.element(document.getElementById('client-' + id)).removeClass('markerRed');
                $timeout(function () {
                    angular.element(document.getElementById('client-' + id)).addClass('markerRed');
                    $timeout(function () {
                        angular.element(document.getElementById('client-' + id)).removeClass('markerRed');
                        $('#client-' + id).scrollTop();
                    }, 300);
                }, 300);
            }, 200);
        }
    }

    vm.selektMarker = function (id, latitude, longitude) {

        angular.element($('.pin-' + id)).addClass('animbounce');
        $timeout(function () {
            angular.element($('.pin-' + id)).removeClass('animbounce');
            $timeout(function () {
                angular.element($('.pin-' + id)).addClass('animbounce');
                $timeout(function () {
                    angular.element($('.pin-' + id)).removeClass('animbounce');
                }, 300);
            }, 300);
        }, 200);
        if (latitude != '' && longitude != '') {
            vm.center = latitude + ", " + longitude;
            vm.zoom = 15;
        } else {
            alert('La position de ce client et inconnu!');
        }
    }

    vm.getCreditClient = function (creditclient, page = null, perpage = 10) {
        ///credit/paginate/
        //alert(creditclient);
        vm.creditclient = true;
        if (creditclient == true) {
            WS.get('clients/encours/paginate/' + perpage, null, page).then(
                function (response) {
                    vm.type = "credit";
                    vm.clients = response.data;

                    $scope.$apply();
                },
                function (error) {
                    console.log(error);
                })
        }
    }
    vm.getcreditformaps = function (code, liste, id) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {
            if (liste[i]['code'] == code) {
                if (liste[i]['solde'] != 0) {
                    return false;
                } else {
                    $('pin-' + id).addClass('pinupdated');
                    return true;
                }
            }
        }
    }
    vm.getMarques = function () {
        WS.get('marques', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.marques = response.data;
                    vm.current_gammes = vm.marques;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

    }
    vm.getGammes = function () {
        WS.get('gammes/select')
            .then(function (response) {
                if (response.status == 200) {
                    vm.gammes = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getFamilles = function () {
        WS.get('familles/select')
            .then(function (response) {
                if (response.status == 200) {
                    vm.familles = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getSousFamilles = function () {
        WS.get('sousfamilles/select')
            .then(function (response) {
                if (response.status == 200) {
                    vm.sousfamilles = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.changeCurrentGamme = () => {
        switch (vm.type_gamme) {
            case 'm':
                vm.current_gammes = vm.marques;
                break;
            case 'g':
                vm.current_gammes = vm.gammes;
                break;
            case 'f':
                vm.current_gammes = vm.familles;
            case 'sf':
                vm.current_gammes = vm.sousfamilles;
                break;
            default:
                break;
        }
    }

    vm.getChiffreByMonth = () => {
        
        WS.getData('clients/getChiffreByMonth', {type : vm.type_gamme, year: vm.year_chiffre, code:  $state.params.code})
            .then(function (response) {
                if (response.status == 200) {
                    vm.list_chiffre = response.data.vente_by_gamme;
                    vm.total_list_chiffre = response.data.vente_by_month;
                    console.log('total_list_chiffre', vm.total_list_chiffre)
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.ajouterEncoursMarque = function (encours) {

        WS.post('clients/encours_commercial', encours)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Encours ajouté avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal($translate.instant('Error'), $translate.instant(response.data.message), 'error');
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.updateEncoursMarque = function (encours) {
        WS.put('clients/encours_commercial/' + encours.id, encours)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Encours modifié avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal($translate.instant('Error'), $translate.instant(response.data.message), 'error');
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getClients = function () {
        WS.get('clients/dashboard')
            .then(function (response) {
                if (response.status == 200) {
                    vm.clients = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang
                    });
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getSecteurs = function () {
        WS.get('secteurs')
            .then(function (response) {
                if (response.status == 200) {

                    vm.secteurs = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.selectRoutes = function () {
        WS.get('routing/selectRoutes')
            .then(function (response) {
                vm.routings = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.getRouteWithDepot = function () {
        WS.get('routing/getRouteWithDepot')
            .then(function (response) {
                vm.routings = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.filterRegionByRoute = function () {
        var region = (vm.region == "" || vm.region == undefined) ? "null" : vm.region;
        var zone = (vm.zone == "" || vm.zone == undefined) ? "null" : vm.zone;
        var secteur = (vm.secteur == "" || vm.secteur == undefined) ? "null" : vm.secteur;
        WS.get('routing/filterRegionByRoute/' + vm.routing_code + '/' + region + '/' + zone + '/' + secteur)
            .then(function (response) {
                //vm.regions = response.data.regions;
                vm.clients = response.data.clients;
                vm.centerroutings = response.data.center.center;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.filterRegionByRouteSecondMethod = function () {
        var routing_code = (vm.routing_code == "" || vm.routing_code == undefined) ? "null" : vm.routing_code;
        WS.get('routing/filterRegionByRouteSecondMethod/' + vm.routing_code)
            .then(function (response) {

                vm.clients = response.data.clients;
                vm.centerroutings = response.data.center.center;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.getZones = function () {
        WS.get('zones')
            .then(function (response) {
                if (response.status == 200) {
                    vm.zones = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getRegion = function () {
        WS.get('regions')
            .then(function (response) {
                if (response.status == 200) {

                    vm.regions = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getGouvernorats = function () {
        WS.get('gouvernorats')
            .then(function (response) {
                if (response.status == 200) {
                    vm.gouvernorats = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.selectDelegations = function () {
        WS.get('delegations/selectDelegations')
            .then(function (response) {
                if (response.status == 200) {
                    vm.delegations = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectDepot = function () {
        WS.get('depots/selectDepot')
            .then(function (response) {
                vm.selectdepot = response.data;
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectLocalites = function (delegation_code = null) {
        WS.getData('localites/selectLocalites', { delegation_code })
            .then(function (response) {
                if (response.status == 200) {

                    vm.localites = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getcategories = function () {
        WS.get('categoriescommerciale')
            .then(function (response) {
                if (response.status == 200) {

                    vm.categories = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getDetails = function () {
        WS.get('clients/detailClient/' + $state.params.id)
            .then(function (response) {
                if (response.status == 200) {
                    vm.detail = response.data;
                    vm.client_code = response.data.code;
                    vm.detail.remise_client = vm.detail.remise_client.toString();
                    vm.detail.bloque = vm.detail.bloque.toString();
                    vm.detail.ticket_cadeaux = vm.detail.ticket_cadeaux.toString();
                    vm.detail.autorisation_paiement_instance = vm.detail.autorisation_paiement_instance.toString();
                    $scope.$apply();
                    vm.selectLocalites(vm.detail.delegation);
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getcreditencours = function () {
        WS.getData('rapports/creditEncoursParClientId/' + $state.params.id)
            .then(response => {
                vm.creditencours = response.data;
                vm.total_sold_commercial = _.reduce(vm.creditencours, function (memo, num) { return memo + num.sold_commercial; }, 0);
                //console.log(vm.total_sold_commercial);
                $scope.$apply();
            });
    };

    vm.getPlafondCreditByClient = function () {

        WS.getData('mouvement_clients/getPlafondCreditByClient', { id: $state.params.id })
            .then(function (response) {
                if (response.status == 200) {
                    vm.plafondByUser = response.data;
                    //console.log(vm.plafondByUser);
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getcreditencoursSS = function () {
        WS.getData('rapports/creditEncoursParClientIdSS/' + $state.params.id)
            .then(response => {
                vm.creditencoursSS = response.data;
                vm.total_sold_SS = _.reduce(vm.creditencoursSS, function (memo, num) { return memo + num.sold_SS; }, 0);

                $scope.$apply();
            });
    };

    vm.getPlafondCreditByClientSS = function () {

        WS.getData('mouvement_clients/getPlafondCreditByClientSS', { id: $state.params.id })
            .then(function (response) {
                if (response.status == 200) {
                    vm.plafondBySS = response.data;

                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getTotalCredit = function (list, attribut) {
        if (!list) return 0;
        var total = 0;
        for (var i = 0; i < list.length; i++) {
            total = total + Number(list[i][attribut]);
        }
        return total;
    }

    vm.date_debut = '';
    vm.date_fin = '';
    vm.getMouvements = function () {
        WS.get('clients/mouvements/' + $state.params.id + `?date_debut=${vm.date_debut}&date_fin=${vm.date_fin}&commercial=${vm.commercial}`)
            .then(function (response) {
                if (response.status == 200) {
                    vm.mouvements = response.data;
                    $scope.$apply();
                    // $('#example').DataTable({
                    //     "language": DatatableLang,
                    //     "order": [
                    //         [1, 'DESC']
                    //     ],
                    //     "buttons": [
                    //         {
                    //             extend: "excel",
                    //             className: "btn-md z-depth-0 border-0 margin-0"
                    //         }
                    //     ]
                    // });
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.extraitClient = () => {
        var data = {
            'client_id': $state.params.id,
            'date_debut': vm.date_debut,
            'date_fin': vm.date_fin,
            'commercial': vm.commercial,
        }
        WS.getData('mouvement_clients/extraitClient', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.extrait_client = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    var object_mvt = {
        sheetid: 'Mouvement-client_' + moment().format("DD-MM-YYYY_HH:mm:ss"),
        headers: true,
        // caption: {
        //     title: 'Mouvement-client_' + moment().format("DD-MM-YYYY_HH:mm:ss"),
        //     style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
        // },
        column: {
            style: 'font-size:20px;background:#d1d1d1;'
        },
        columns: [
            { columnid: 'user', title: 'Commercial' },
            { columnid: 'date_mouvemenet', title: 'Date' },
            { columnid: 'entetcommercial_code', title: 'Code Bl' },
            { columnid: 'mouvement_libelle', title: 'Libelle' },
            { columnid: 'montant', title: 'Montant' },
            { columnid: 'sens_ecriture', title: 'Sense Ecriture' },
            { columnid: 'payement_code', title: 'Payement code' },
            { columnid: 'entetcommercial_numero', title: 'Numéro' },
            { columnid: 'date_echeance', title: 'Date Echéance' },
            { columnid: 'commentaire', title: 'Commentaire' }

        ],
        row: {
            style: function (sheet, row, rowidx) {
                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
        }
    };

    vm.exportMouvementClient = function () {
        // console.log('vm.mouvements');
        // console.log(vm.mouvements);
        var filename = 'Mouvement-client_' + moment().format("DD-MM-YYYY_HH:mm:ss");
        alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [object_mvt, vm.mouvements]);

    };

    vm.exportExtraitClient = function () {
        // console.log('vm.mouvements');
        // console.log(vm.mouvements);
        var filename = 'Mouvement-client_' + moment().format("DD-MM-YYYY_HH:mm:ss");
        alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [object_extrait, vm.extrait_client]);

    };

    vm.getMontantTotalByClient = function () {

        WS.getScache('entetecommercial/getMontantTotalByClient/' + $state.params.code)
            .then(function (response) {
                if (response.status == 200) {
                    vm.gettotalcls = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getfidelites = function () {

        WS.get('clients/fidelites/' + $state.params.id)
            .then(function (response) {
                if (response.status == 200) {
                    vm.fidelites = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang
                    });
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getHistoriques = function () {
        WS.getScache('entetecommercial/byclientcode/' + $state.params.code)
            .then(function (response) {
                if (response.status == 200) {
                    vm.historiques = response.data;

                    $('#mapsHistoryContainer').empty();
                    $('#mapsHistoryContainer').html('<div style="width: 100%; height: 75vh" id="mapsHistoryContainer"></div>');

                    const latitude = vm.historiques[0].client.latitude;
                    const longitude = vm.historiques[0].client.longitude;
                    vm.zoomRouting = 11;
                    var mymap = L.map('mapsHistoryContainer').setView([latitude, longitude], vm.zoomRouting);
                    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
                        foo: 'bar',
                        attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
                    }).addTo(mymap);
                    var redMarker = L.ExtraMarkers.icon({
                        icon: 'fa-home',
                        markerColor: 'blue',
                        shape: 'circle', //'circle', 'square', 'star', or 'penta'
                        prefix: 'fa'
                    });

                    var marker = L.marker([latitude, longitude], { icon: redMarker }).addTo(mymap);

                    const nom = vm.historiques[0].client.nom;
                    const prenom = vm.historiques[0].client.prenom;
                    const id = vm.historiques[0].client.id;
                    const code = vm.historiques[0].client.code;
                    const code_a_barre = vm.historiques[0].client.code_a_barre;
                    const routing_code = vm.historiques[0].client.routing_code;
                    const mobile = vm.historiques[0].client.mobile;
                    const user = vm.historiques[0].commercial.nom + ' ' + vm.historiques[0].commercial.prenom;
                    var info = $compile(`
                                             <div id="bodyContent" style="width=300px;">
                                                  <p class="text-center">
                                                    <a ui-sref="app.clients.details({id : ${id} })" target="_blank">
                                                        Modifier
                                                    </a>
                                                  </p>
                                                  <b>Client</b> : ${nom} ${prenom}</a></br>
                                                  <b>Code</b> : ${code}</a></br>
                                                  <b>Code_a_barre</b> : ${code_a_barre}</br>
                                                  <b>Route</b> : ${routing_code}</br>
                                                  <b>Mobile</b> : ${mobile}</br>
                                                  <b>Commercial</b> : ${user}</br>
                                                </div>
                                            `)($scope);
                    var popup = L.popup({
                        maxWidth: 600,
                        minWidth: 200
                    }).setContent(info[0])
                    marker.bindPopup(popup);


                    $scope.$apply();
                    $('.table_historiques').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getImages = function (code) {
        var url =
            'images/' + code;
        WS.get(url)
            .then(function (response) {
                vm.imagespdv = response.data;
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getPathOfImage = function (type, liste) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {
            if (liste[i]['type'] == type) {
                return liste[i];
            }
        }
    };

    vm.getFidelitesLite = function () {
        WS.get('fidelites/lite')
            .then(function (response) {

                if (response.status == 200) {
                    vm.fideliteslite = response.data;

                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getActivites = function () {
        WS.get('activites')
            .then(function (response) {
                if (response.status == 200) {
                    vm.activites = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getSpecialites = function () {
        WS.get('specialites')
            .then(function (response) {
                if (response.status == 200) {
                    vm.specialites = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getusers = function () {
        WS.get('users/query/grade="LIV"', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.users = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getLibelleBycode = function (code, liste, attribute) {


        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] == code) {
                return liste[i];
            }

        }
    }
    vm.getSecteurs = function () {
        WS.get('secteurs')
            .then(function (response) {
                if (response.status == 200) {
                    vm.secteurs = response.data;
                    console.log(vm.secteurs);
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    /* vm.getZones = function() {
    WS.get('zones')
        .then(function(response) {
            if (response.status == 200) {
                vm.zones = response.data;
                $scope.$apply();
            }
        })
        .then(null, function(error) {
            console.log(error);
        });
}
*/
    vm.getsolde = function (code, liste) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {
            if (liste[i]['code'] == code) {
                return liste[i];
            }
        }
        return { 'solde': '' }
    }
    vm.getCAByRegion = function (code, liste) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {
            if (liste[i]['code'] == code) {
                return liste[i];
            }
        }
    }
    vm.getLocalites = function () {
        WS.get('localites')
            .then(function (response) {
                if (response.status == 200) {
                    vm.localites = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getCAByRegion = function (code, liste, attribute) {
        if (!liste) return;


        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] == code) {
                return liste[i];
            }

        }
    }
    // WS.get('paiements/getetatCreditByClients')
    //     .then(function (response) {
    //         if (response.status == 200) {
    //             vm.getetatCreditByClients = response.data;

    //             $scope.$apply();
    //         } else { }
    //     })

    // WS.get('fidelites/total')
    //     .then(function (response) {
    //         if (response.status == 200) {
    //             vm.fidelitesparclient = response.data;
    //             $scope.$apply();
    //         } else { }
    //     })

    vm.getRegions = function () {
        WS.get('regions')
            .then(function (response) {
                if (response.status == 200) {
                    vm.regions = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.ajoutermouvement = function (mouvement, code) {
        mouvement.client_code = code;
        WS.post('mouvement_clients/ajoutermouvement', mouvement)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        $translate.instant('Succé'),
                        $translate.instant('Mouvement ajoutée avec succées'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    })
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    $scope.moveCSV = function (element) {
        var reader = new FileReader();
        reader.onload = function (event) {
            vm.csvfile.file = event.target.result;
            $scope.$apply(function () {
                vm.files = element.files;
            });
        }
        reader.readAsDataURL(element.files[0]);
    };


    vm.getSousSocietes = function () {
        WS.get('soussocietes/selectSoussocietes')
            .then(function (response) {
                if (response.status == 200) {
                    vm.soussocietes = response.data;
                    if (vm.soussocietes.length == 1) {
                        vm.detail.soussociete_code = vm.soussocietes[0].code;
                    }
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getBlNonSolde = function () {
        WS.get('entetecommercial/getblnonsolde' + '/' + vm.client_code)
            .then(function (response) {
                if (response.status == 200) {
                    vm.blsnonsoldes = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.exportHistoriqueExcel = function (bldate, withavoir = null, custom = false) {
        if (bldate == undefined || bldate == {}) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir les deux champs date'), 'error');
            return;
        } else if (bldate.date_debut == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date debut'), 'error');
            return;
        } else if (bldate.date_fin == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date fin'), 'error');
            return;
        } else {
            bldate.client_code =$state.params.code;
            var url = 'bl/getClientByDateExport/' + bldate.date_debut + '/' + bldate.date_fin +'/'+bldate.client_code;
            

            WS.get(url)
                .then(function (response) {
                    var date_debut = response.data[response.data.length - 1].date_creation;
                    var date_fin = response.data[0].date_creation;
                    var filename = 'Bl-' + date_debut + '-_-_-' + date_fin;
                    if (withavoir) {
                        filename = 'Bl+avoir-' + date_debut + '-_-_-' + date_fin;
                    }
                 

                    var all_data = [
                        { columnid: 'code', title: 'Code' },
                        { columnid: 'code_erp_produit', title: 'code_erp produit' },
                        { columnid: 'produit', title: 'Produit' },
                        { columnid: 'marque', title: 'Marque' },
                        { columnid: 'gamme', title: 'Gamme' },
                        { columnid: 'famille', title: 'Famille' },
                        { columnid: 'sousfamille', title: 'Sous_Famille' },
                        { columnid: 'fournisseur', title: 'Fournisseur' },
                        { columnid: 'entetecommercial_code', title: 'BL_N°' },
                        { columnid: 'quantite', title: 'Quantite' },
                        { columnid: 'pu_ht', title: 'PU_HT' },
                        { columnid: 'pu_ttc', title: 'PU_TTC' },
                        { columnid: 'prix_achat_ttc', title: 'prix_achat' },
                        { columnid: 'p_tva', title: 'p_tva' },
                        { columnid: 'taux_tva', title: 'TVA' },
                        { columnid: 'total', title: 'Total' },
                        { columnid: 'total_net', title: 'Total Net' },
                        { columnid: 'total_ht', title: 'Total HT' },
                        { columnid: 'total_ht_net', title: 'Total HT Net' },
                        { columnid: 'date_creation', title: 'DATE' },
                        { columnid: 'taux_remise', title: 'Taux_remise' },
                        { columnid: 'remise', title: 'Remise' },
                        { columnid: 'remise_commande', title: 'Remise escompte' },
                        { columnid: 'remise_pied', title: 'Remise pied' },
                        { columnid: 'type', title: 'Type' },
                        { columnid: 'code_commercial', title: 'Code Commercial' },
                        { columnid: 'commercial', title: 'Commercial' },
                        { columnid: 'code_client', title: 'code_client' },
                        { columnid: 'code_erp_client', title: 'code_erp_client' },
                        { columnid: 'adresse_facturation', title: 'Adresse' },
                        { columnid: 'matricule_fiscale', title: 'Matricule fiscale' },
                        { columnid: 'rc', title: 'RC' },
                        { columnid: 'presentoire', title: 'presentoire' },
                        { columnid: 'client', title: 'Client' },
                        { columnid: 'magasin', title: 'Magasin' },
                        { columnid: 'routing_code', title: 'Route' },
                        { columnid: 'region', title: 'Region' },
                        { columnid: 'gouvernorat', title: 'Gouvernorat' },
                        { columnid: 'zone', title: 'Zone' },
                        { columnid: 'categorie', title: 'Categorie' },
                        { columnid: 'activite', title: 'Activite' },
                        { columnid: 'longitude', title: 'longitude piece' },
                        { columnid: 'latitude', title: 'latitude piece' },
                        { columnid: 'longitudeClient', title: 'longitude Client' },
                        { columnid: 'latitudeClient', title: 'latitude Client' }
                    ];

                    var custom_data = [
                        { columnid: 'entetecommercial_code', title: 'BL_N°' },
                        { columnid: 'code_client', title: 'code_client' },
                        { columnid: 'code', title: 'Code' },
                        { columnid: 'type', title: 'Type' },
                        { columnid: 'total_net', title: 'Total Net' },
                        { columnid: 'total_ht', title: 'Total HT' },
                        { columnid: 'total_ht_net', title: 'Total HT Net' },
                        { columnid: 'remise', title: 'Remise' },
                        { columnid: 'quantite', title: 'Quantite' },
                        { columnid: 'pu_ht', title: 'PU_HT' },
                        { columnid: 'pu_ttc', title: 'PU_TTC' },
                        { columnid: 'p_tva', title: 'p_tva' },
                        { columnid: 'taux_tva', title: 'TVA' },
                        { columnid: 'total', title: 'Total' },
                        { columnid: 'date_creation', title: 'DATE' },
                        { columnid: 'taux_remise', title: 'Taux_remise' },
                        { columnid: 'remise_pied', title: 'Remise pied' },
                        { columnid: 'commercial', title: 'Commercial' },
                        { columnid: 'code_commercial', title: 'Code Commercial' }
                    ];
                    var stylecsv = {
                        sheetid: filename,
                        headers: true,
                        caption: {
                            title: filename,
                            style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
                        },
                        //style:'background:#fff',
                        column: {
                            style: 'font-size:20px;background:#d1d1d1;'
                        },
                        columns: custom ? custom_data : all_data,
                        row: {
                            style: function (sheet, row, rowidx) {
                                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                            }
                        }
                    };
                    alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsv, response.data]);
                    alasql('SELECT * INTO CSV("' + filename + '.csv",?) FROM ?', [stylecsv, response.data]);
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }
    };


    vm.savecsv = function () {
        vm.csvfile.file = vm.files[0];
        WS.postwithimg('clients/saveCsv', vm.csvfile, "POST")
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succée'),
                        $translate.instant(response.data.message),
                        'success'
                    );
                } else {
                    swal(
                        $translate.instant('Warning'),
                        $translate.instant(response.data.message),
                        'warning'
                    );
                }
            }, function (error) {
                console.log(error);
            });
    };

    /*  vm.store = function(detail) {

        WS.post('clients', vm.detail)
            .then(function(response) {
                if (response.data.success == true) {
                    if ($state.params.id) {
                        swal(
                            'Succée',
                            'Client mis à jour avec succées',
                            'success'
                        ).then(function() {
                            $window.location.reload();
                        })
                    } else {
                        swal(
                            'Succée',
                            'Client ajoutée avec succées',
                            'success'
                        ).then(function() {
                            $window.location.reload();
                        })
                    }


                } else if (response.data.success == false) {
                    swal(
                        'Error',
                        response.data.message,
                        'error'

                    );
                }
            })
            .then(null, function(error) {
                console.log(error);
                swal(
                    'ERROR !',
                    error.data.message,
                    'error'
                )

            });
    }
*/

    vm.store = function (detail) {
        WS.post('clients/saveWeb', vm.detail)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Client ajouté avec succées'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal(
                        $translate.instant('Error'),
                        $translate.instant(response.data.message), 'error');
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }




    /*vm.store = function() { 


            vm.detail.image = vm.files[0];
            var route;
            var method;
            var msg;
            if($state.params.id){
                 route = 'clients/' + $state.params.id;
                 method = 'PUT';
                 msg = 'modifiée';
            }else{
                route = "clients";
                method= "POST";
                msg = 'ajoutée';
            }

            WS.postwithimg(route , vm.detail , method)
            .then(function(data){
                if (data.status == 200) {
                swal(
                  'Succée',
                  'Client '+ msg +' avec succées',
                  'success'
                ).then(function(){
                    $window.location.reload();
                });  
            } else {}
           },function(error){
                console.log(error);
           });
      };
      $scope.uploadedFile = function(element) {
        $scope.currentFile = element.files[0];
        var reader = new FileReader();
            reader.onload = function(event) {
              vm.detail.image = event.target.result
              $scope.$apply(function() {
                vm.files = element.files;
              });
            }
        reader.readAsDataURL(element.files[0]);
      }*/




    /*  vm.save = function(detail) {
            WS.post('clients', vm.detail)
            .then(function(response) {
                if (response.status == 200) {
               swal(
                      'Succé',
                      'Client ajoutée avec succées',
                      'success'
                    ).then(function(){
                        $window.location.reload();
                    });
                }
            })
            .then(null, function(error) {
                console.log(error);
                swal(
                    'ERROR !',
                    error.data.message,
                    'error'
                )

            });
    }*/



    vm.update = function (detail) {

        WS.put('clients/' + detail.id, detail)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Client modifié avec Succès'),
                        'success'

                    ).then(function () {
                        $window.location.reload();
                    });
                } else if (response.data.success == false) {
                    swal(
                        $translate.instant('Error'),
                        $translate.instant(response.data.message),
                        'error'

                    );
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.bloquer = function (id, description) {
        swal({
            title: '',
            text: $translate.instant("Voulez vous vraiment bloquer ce client!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.post('clients/bloquer', { id: id }).then(
                function () {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant("Suppression avec succé"),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        description,
                        $translate.instant('vous ne pouvez pas bloquer ce client!'),
                        'warning'
                    );
                }
            );
        }, function (error) {
            console.log(error);
        });
    };


    vm.exportCreditToExcel = function () {
        var filename = 'Clients-credit-' + moment().format("DD-MM-YYYY_HH:mm:ss");
        WS.get('clients/getetatCreditByClients').then(
            function (response) {
                alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsvcredit, response.data]);
                alasql('SELECT * INTO CSV("' + filename + '.csv",?) FROM ?', [stylecsvcredit, response.data]);
            },
            function (error) {
                console.log(error);
            }
        );
    };

    var stylecsvcredit = {
        sheetid: 'clients-credit - ' + moment().format("DD-MM-YYYY HH:mm:ss"),
        headers: true,
        caption: {
            title: 'clients-credit - ' + moment().format("DD-MM-YYYY HH:mm:ss"),
            style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
        },
        //style:'background:#fff',
        column: {
            style: 'font-size:20px;background:#d1d1d1;'
        },
        columns: [
            { columnid: 'solde' },
            { columnid: 'code', title: 'Code' },
            { columnid: 'nomclient', title: 'Nom' },
            { columnid: 'adresse_facturation', title: 'Adresse' },
            { columnid: 'mobile', title: 'Mobile' },
            { columnid: 'plafond_credit', title: 'Plafond credit' },
            { columnid: 'routing_code', title: 'Rooting' }

        ],
        row: {
            style: function (sheet, row, rowidx) {
                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
        }
    };






    /**
     *   ***************************************** Activite and Categorie ***************************************
     */
    vm.ajouterActivite = function (activite) {

        WS.post('activites', activite)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('activite ajoutée avec succées'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                        //vm.activites.push(response.data.activite);
                        //$scope.$apply();
                    })
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.ajouterCategorie = function (categorie) {

        WS.post('categoriescommerciale', categorie)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('categorie  ajoutée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                        //console.log(response.data.categorie);
                        //vm.categories.push(response.data.categorie);
                        //$scope.$apply();
                    })
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.updateActivite = function (detail) {
        WS.put('activites/' + detail.id, detail)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Activité modifiée avec Succès'),
                        'success'
                    );
                } else if (response.data.success == false) {
                    swal(
                        $translate.instant('Erreur'),
                        $translate.instant(response.data.message),
                        'error'

                    );
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.updateCategorie = function (detail) {
        WS.put('categoriescommerciale/' + detail.id, detail)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Catégorie modifiée avec Succès'),
                        'success'
                    );
                } else if (response.data.success == false) {
                    swal(
                        $translate.instant('Erreur'),
                        $translate.instant(response.data.message),
                        'error'

                    );
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.deleteActivite = function (id, description) {
        swal({
            title: $translate.instant('Supprimer l\'activité') + ' ' + description + '?',
            text: $translate.instant("Voulez vous vraiment supprimer cette activité!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('activites/' + id).then(
                function () {
                    swal(
                        $translate.instant('Supprimé!'),
                        $translate.instant('Activite supprimé avec Succès.'),
                        'success'
                    ).then(function () {
                        //$window.location.reload();
                        $('#activite_' + id).remove();
                    });
                },
                function () {
                    swal(
                        $translate.instant('Warning!'),
                        $translate.instant('Impossible de supprimer cette activité'),
                        'warning'
                    );
                }
            );
        });
    };

    vm.deleteCategorie = function (id, description) {
        swal({
            title: $translate.instant('Supprimer') + ' ' + description + '?',
            text: $translate.instant("Voulez vous vraiment supprimer cette Catégorie!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non!'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('categoriescommerciale/' + id).then(
                function () {
                    swal(
                        $translate.instant('Supprimé'),
                        $translate.instant('Catégorie supprimée avec Succès.'),
                        'success'
                    ).then(function () {
                        //$window.location.reload();
                        $('#categorie_' + id).remove();
                    });
                },
                function () {
                    swal(
                        $translate.instant('Attention!'),
                        $translate.instant('Impossible de supprimer cette Catégorie!'),
                        'warning'
                    );
                }
            );
        });
    };



    vm.getRapportVisites = function () {

        WS.get('visites/byClient' + '/' + $state.params.code)
            .then(function (response) {
                if (response.status == 200) {
                    vm.rapportvisites = response.data;
                    $scope.$apply();
                    $('.table_rapport-visite').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getNbrBlNbrVisite = function () {

        WS.get('visites/getNbrBlNbrVisite' + '/' + $state.params.code)
            .then(function (response) {
                if (response.status == 200) {
                    vm.visitesclient = response.data.visites;
                    vm.blslient = response.data.bls;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getEncours = function () {
        WS.get('clients/encours_commercialAll')
            .then(function (response) {
                if (response.status == 200) {
                    vm.encours = response.data;

                    $scope.$apply();
                    $('.table_encours').DataTable({
                        "language": DatatableLang
                    });

                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getProduitByClient = function () {

        WS.get('entetecommercial/getProduitByClient' + '/' + $state.params.code)
            .then(function (response) {
                if (response.status == 200) {
                    vm.produitsparclient = response.data;

                    $scope.$apply();

                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getRapportVisitesParMois = function () {
        //alert('called')
        WS.get('visites/rapportVisiteParMois' + '/' + $state.params.code + '/' + vm.year)
            .then(function (response) {
                if (response.status == 200) {
                    vm.rapportvisitesparmois = response.data.visite;
                    vm.rapportblparmois = response.data.total;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getRapportCommandeParMois = function () {

        WS.get('entetecommercial/getRapportCommandeParMois' + '/' + $state.params.code + '/' + vm.year)
            .then(function (response) {
                if (response.status == 200) {
                    vm.rapportcommandeparmois = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getMontantByCodeClient = function () {

        WS.get('entetecommercial/getMontantByCodeClient' + '/' + $state.params.code)
            .then(function (response) {
                if (response.status == 200) {
                    vm.montantbycodeclient = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getNombreVisiteParClient = function (code, liste) {


        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i]['mois'] == code) {
                return liste[i];
            }

        }
    }
    vm.getValue = function (liste, attribute, value, type) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] == value && liste[i]['type'] == type) {
                return liste[i];
            }


        }
    }

    vm.getPercent = function (liste, valeur) {
        if (!liste) return;
        var total = 0;
        var percents = 0;
        for (var i = 0; i < liste.length; i++) {
            total = total + liste[i].total;
        }
        percents = (valeur / total) * 100;

        return percents;
    };


    /**
     * Gamme Historique
     */
    vm.HistoriqueClientByGammeProd = function () {
        WS.get('entetecommercial/HistoriqueClientByGammeProd/' + $state.params.code)
            .then(function (response) {
                if (response.status == 200) {
                    var chart = AmCharts.makeChart("HistoriqueClientByGammeProd", {
                        "type": "pie",
                        "theme": "light",
                        "dataProvider": response.data,
                        "valueField": "total",
                        "titleField": "gamme",
                        "colorField": "color",
                        "labelRadius": 0,
                        "legend": {
                            "position": "bottom",
                            "marginRight": 0,
                            "align": "left",
                            "equalWidths": true,
                            "valueWidth": 35,
                            "horizontalGap": 5,
                            "marginLeft": 0,
                            "marginBottom": 0,
                            "marginTop": 0,
                            "autoMargins": false
                        },
                        "innerRadius": "65%",
                        "labelsEnabled": true,
                        "balloonText": "[[title]]<br><span style='font-size:11px'><b>[[total]]</b> ([[percents]]%)</span>",
                        "autoMargins": false,
                        "marginTop": 0,
                        "marginBottom": 7,
                        "marginLeft": 0,
                        "marginRight": 0,
                        "pullOutRadius": 0,
                        "balloon": {
                            "fixedPosition": true
                        },
                        "export": {
                            "enabled": true
                        }
                    });
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getLivraisonHistorique = function () {
        WS.get('clients/getLivraisonHistorique/' + $state.params.code)
            .then(function (response) {
                if (response.status == 200) {
                    vm.livraisons = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.importWavesoftClients = function () {
        vm.csvfile.file = vm.files[0];
        WS.postwithimg('clients/importWavesoftClients', vm.csvfile, "POST")
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        response.data.message,
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Warning',
                        response.data.message,
                        'warning'
                    );
                }
                if (response.data.error == true) {
                    swal(
                        'Warning',
                        '',
                        'warning'
                    );
                }
            }, function (error) {
                console.log(error);
            });
    };

    vm.exportWavesoftClients = function () {
        $window.location.href = AppSettings.apiUrl + 'clients/exportWavesoftClients';
    };

    vm.selectUsers = () => {
        WS.get('users/selectUsers')
            .then(function (response) {
                vm.selectusers = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectAllUsers = function () {
        WS.get('users/selectAllUsers')
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectallusers = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectActivites = () => {
        WS.get('activites/selectActivites')
            .then(function (response) {
                vm.activites = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectSpecialites = () => {
        WS.get('specialites')
            .then(function (response) {
                vm.specialites = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectRegions = () => {
        WS.get('regions/selectRegions')
            .then(function (response) {
                vm.regions = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.selectGouvernorats = () => {
        WS.get('gouvernorats/selectGouvernorats')
            .then(function (response) {
                vm.gouvernorats = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectZones = () => {
        WS.get('zones/selectZones')
            .then(function (response) {
                vm.zones = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    /*
     * update routing groups
     */

    vm.getClientforUpdateRoutes = function () {
        var data = {
            activite_code: vm.activite_code,
            region_code: vm.region_code,
            zone_code: vm.zone_code,
            gouvernorat_code: vm.gouvernorat_code,
            routing_code: vm.routing_code,
            user_code: vm.user_code,
            credit: vm.credit,
            adv_search: vm.adv_search,
            specialite: vm.specialite,
            date_debut_credit: vm.date_debut_credit,
            date_debut_visite: vm.date_debut_visite,
            date_fin_credit: vm.date_fin_credit,
            date_fin_visite: vm.date_fin_visite,
            tournee: vm.adv_tournee,

        };


        WS.getData('clients/getClientforUpdateRoutes', data)
            .then(function (response) {
                vm.listclients = response.data;
                vm.group_clients = [];
                _.each(vm.listclients, (element, index) => {
                    vm.group_clients.push(element.code);
                });
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.centerMapsforUpdateRoutings = function (page = 1, perpage = 15) {
        var url = `clients/centerMapsforUpdateRoutings?` +
            `&activite_code=${vm.activite_code}` +
            `&date=${vm.date}` +
            `&region_code=${vm.region_code}` +
            `&gouvernorat_code=${vm.gouvernorat_code}` +
            `&routing_code=${vm.routing_code}` +
            `&user_code=${vm.user_code}` +
            `&adv_search=${vm.adv_search}` +
            `&credit=${vm.credit}`;
        WS.get(url)
            .then(function (response) {
                vm.centermaps = response.data.center;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.manage_checks = (value, client_code) => {

        if (value == true) {
            vm.group_clients.push(client_code);
        }
        if (value == false) {
            vm.group_clients = _.without(vm.group_clients, client_code);
        }
    };

    vm.check_box_all = () => {

        if (vm.check_all == true) {
            vm.group_clients = [];
            _.each(vm.listclients, (element, index) => {
                vm.group_clients.push(element.code);
            });
        }
        if (vm.check_all == false) {
            vm.group_clients = [];
        }
    };

    vm.update_all_routes = () => {
        if (vm.new_routing_code != undefined && vm.new_routing_code != '' && vm.new_routing_code != null && vm.group_clients.length > 0) {
            var data = {
                new_routing_code: vm.new_routing_code,
                clients: vm.group_clients
            };
            // console.log(data);

            WS.post('clients/update_all_routes', data)
                .then(function (response) {
                    if (response.status == 200) {
                        swal(
                            $translate.instant('Succés'),
                            $translate.instant('routes modifié avec succès'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        })
                    }
                })
                .then(null, function (error) {
                    console.log(error);
                });
        } else {
            swal(
                $translate.instant('Succés'),
                $translate.instant('Veuillez bien choisir une nouvelle route et au mois un client'),
                'warning'
            );
        }
    };

    vm.getImagesByCodeABarre = (code_a_barre) => {
        WS.get('clients/getImagesByCodeABarre/' + code_a_barre)
            .then(function (response) {
                if (response.data.length > 0) {
                    vm.imagesclient = response.data;
                    jQuery.noConflict();
                    $('#imagesClient').modal('show');
                    $scope.$apply();
                } else {
                    alert($translate.instant('Aucune image correspond a ce client'));
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getImagesByCodeABarreByType = (code_a_barre, type) => {
        WS.get('clients/getImagesByCodeABarreByType/' + code_a_barre + '/' + type)
            .then(function (response) {
                if (response.data) {

                    vm.imagesclientFile = response.data.path;
                    jQuery.noConflict();
                    $('#imagesClient').modal('show');
                    $scope.$apply();
                } else {
                    alert($translate.instant('Aucune image trouvée'));
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.addTourneeCredit = function () {
        if (vm.group_clients) {
            var data = {
                //libelle: vm.libelle_tournee,
                depot_code: vm.depot_code,
                code_jour: vm.code_jour,
                frequence: vm.frequence,
                routing_code: vm.routing_code_tourne,
                date_debut: vm.date_debut,
                date_fin: vm.date_fin,
                group_clients: vm.group_clients,
                dates: vm.dates
            };

            WS.post('clients/addTourneeCredit', data)
                .then(function (response) {
                    if (response.data.success == true) {
                        swal(
                            $translate.instant('Succée'),
                            $translate.instant('Tournee Ajoutée avec succé'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        })
                    } else {
                        swal(
                            $translate.instant('Succée'),
                            $translate.instant(response.data.message),
                            'success'
                        );
                    }
                })
                .then(null, function (error) {
                    console.log(error);
                });
        } else {
            swal(
                $translate.instant('Oups!'),
                $translate.instant('Veuillez bien choisir au moins un client'),
                'warning'
            );

        }
    }

    /**
     * list of cls encours
     */
    // vm.listofcls_encours = [];
    // vm.manage_tab_encours = function (client_code) {
    //     //console.log(client_code, vm.cl_encours[client_code].checked);
    //     if (vm.cl_encours[client_code].checked) {
    //         //add client_code to list vm.listofcls_encours
    //         vm.listofcls_encours.push(client_code);
    //     } else {
    //         //remove client_code to list vm.listofcls_encours
    //         vm.listofcls_encours = _.without(vm.listofcls_encours, _.findWhere(vm.listofcls_encours, client_code));
    //     }


    // };


    vm.enregistrerEncours = function () {
        var list_cls = [];
        var i = 0;
        if (vm.cl_encours.user_code == undefined && vm.cl_encours.user_code == "") { i++; }
        if (vm.cl_encours.plafond && vm.cl_encours.plafond != "") {
            _.each(vm.cl_encours.cls, (element, index) => {
                if (element.checked == true) {
                    list_cls.push(element);
                }
            });
            if (list_cls.length == 0) {
                i++;
            }
        } else {
            _.each(vm.cl_encours.cls, (element, index) => {
                if (element.checked == true) {
                    if (element.plafond && element.plafond != "") {
                        list_cls.push(element);
                    } else {
                        i++;
                    }
                }
            });
        }
        if (i > 0) {
            swal('Attention', 'Veuillez bien vérifier les champs', 'warning');
        } else {
            vm.cl_encours.cls = list_cls;

            WS.post('clients/encours_commercial', vm.cl_encours)
                .then(function (response) {
                    if (response.data.success == true) {
                        swal(
                            $translate.instant('Succès'),
                            $translate.instant('Encours ajouté avec succées'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        });

                    } else if (response.data.success == false) {
                        swal('Error', response.data.message, 'error');
                    }
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }

    }

    vm.reselectClsEncourMarque = () => {
        if (vm.selectall_encours == true) {
            _.each(vm.cl_encours.cls, (element, index) => {
                vm.cl_encours.cls[index].checked = true;
            })
        } else {
            _.each(vm.cl_encours.cls, (element, index) => {
                vm.cl_encours.cls[index].checked = false;
            })
        }
    };


    $(document).ready(function () {

        $(".datepicker-multiple").datepicker({
            autoClose: false,
            onSelect: function (date) {
                vm.dates = date;
                $scope.$apply();
                //console.log(vm.month);
            }
        });
    })


    vm.corrigerSoussociete = () => {
        WS.post('clients/corrigerSoussociete', { client_id: $state.params.id })
            .then(function (response) {
                if (response.data.success) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('action effectuée avec succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                }

            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    /***
     * NEW PAGE LISTE CLIENTS
     */
    vm.list_clients = [];
    vm.btn_load_more = true;
    vm.new_page = 0;
    vm.getLists = function (perpage = 10) {
        //alert(vm.creditclient)
        if (vm.new_page == 0) {
            vm.list_clients = [];
        }
        vm.new_page += 1;
        var data = {
            perpage,
            page: vm.new_page,
            categoriecommercial_code: vm.categoriecommercial_code,
            activite_code: vm.activite_code,
            zone_code: vm.zone_code,
            region_code: vm.region_code,
            gouvernorat_code: vm.gouvernorat_code,
            routing_code: vm.routing_code,
            user_code: vm.user_code,
            adv_search: vm.adv_search,
            credit: vm.credit,
            etat: vm.etat,
        };
        var url = `clients/new_list_clients`;
        WS.getData(url, data)
            .then(function (response) {
                _.each(response.data.data, element => {
                    vm.list_clients.push(element);


                })
                vm.total = response.data.total;
                if (response.data.total == vm.list_clients.length) {
                    vm.btn_load_more = false;
                } else {
                    vm.btn_load_more = true;

                }
                $scope.$apply();

                _.each(response.data.data, element => {
                    /**
                     * add maps
                     */
                    if (element.latitude && element.longitude) {
                        $(`#map_cl_${element.id}`).empty();
                        $(`#map_cl_${element.id}`).append(`<div style="width: 270px; height: 148px" id="panel_map_${element.id}"></div>`);

                        var osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
                        var osmAttrib = '&copy; <a href="http://nomadis.online/">Nomadis</a>';
                        var osm = L.tileLayer(osmUrl, {
                            maxZoom: 18,
                            attribution: osmAttrib
                        });
                        //console.log('from maps : ', vm.getpays)
                        var map = new L.Map(`panel_map_${element.id}`, {
                            center: new L.LatLng(element.latitude, element.longitude),
                            zoom: 11,
                            fullscreenControl: true,
                            fullscreenControlOptions: {
                                position: 'topright'
                            }
                        });

                        osm.addTo(map);
                        if (element.latitude && element.latitude) {
                            var MarkerUserIcon = L.ExtraMarkers.icon({
                                icon: 'fa-home',
                                markerColor: 'blue',
                                shape: 'star', //'circle', 'square', 'star', or 'penta'
                                prefix: 'fa'
                            });
                            var MarkerUser = L.marker([element.latitude, element.longitude], { icon: MarkerUserIcon }).addTo(map);

                            var infoUserMarker = $compile(`
                                                         <div id="bodyContent" style="width=200px;">
                                                              <div><b>Code</b> : ${element.code}</div>
                                                              <div><b>Code ERP</b> : ${element.code_erp}</div>
                                                              <div><b>Code a barre</b> : ${element.code_a_barre}</div>
                                                              <div><b>Client</b> : ${element.nom} ${element.prenom}</div>
                                                              <div><b>Commercial</b> : ${element.user_l}</div>
                                                              <div><b>Magasin</b> : ${element.magasin}</div>
                                                              <div><b>Adresse</b> : ${element.adresse_facturation}</div>
                                                              <div><b>Localite</b> : ${element.gouvernorat_l} ${element.region_l} ${element.zone_l}</div>
                                                              <div><b>Activite</b> : ${element.activite_l} </div>
                                                              <div><b>Routing</b> : ${element.routing_code} </div>
                                                              <div><b>Crédit</b> : ${element.encours}</div>
                                                        </div>
                                                        `)($scope);
                            var popupInfoUser = L.popup({
                                maxWidth: 600,
                                minWidth: 300
                            }).setContent(infoUserMarker[0])
                            MarkerUser.bindPopup(popupInfoUser);
                        }
                    }

                })
            })
            .then(null, function (error) {
                console.log(error);
            });
    }



    vm.getNotesByClient = (client_code) => {
        WS.getData('notes/getNotesByClient', { client_code })
            .then(function (response) {
                vm.notes = response.data;
                $scope.$apply();

            })
            .then(null, function (error) {
                console.log(error);
            });
    };




    var object_mvt = {
        sheetid: 'Mouvement-client_' + moment().format("DD-MM-YYYY_HH:mm:ss"),
        headers: true,
        column: {
            style: 'font-size:20px;background:#d1d1d1;'
        },
        columns: [
            { columnid: 'user', title: 'Commercial' },
            { columnid: 'date_mouvemenet', title: 'Date' },
            { columnid: 'entetcommercial_code', title: 'Code Bl' },
            { columnid: 'mouvement_libelle', title: 'Libelle' },
            { columnid: 'montant', title: 'Montant' },
            { columnid: 'sens_ecriture', title: 'Sense Ecriture' },
            { columnid: 'payement_code', title: 'Payement code' },
            { columnid: 'entetcommercial_numero', title: 'Numéro' },
            { columnid: 'date_echeance', title: 'Date Echéance' },
            { columnid: 'commentaire', title: 'Commentaire' }

        ],
        row: {
            style: function (sheet, row, rowidx) {
                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
        }
    };



    vm.export_extrait = function (tableId) {
        const data = {
            date_debut: vm.details_date_debut,
            date_fin: vm.details_date_fin,
            commercial: vm.details_commercial,
            categorie: vm.details_categorie,
            colisage: vm.details_colisage
        };
        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;
        var title = data.date_debut + '_||_' + data.date_fin + '_||_' + data.commercial +
            '_||_' + data.categorie + '_||_' + data.colisage;

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);

    };

    vm.exportVeuilles = function () {
        var config = {
            sheetid: 'veuilles-' + moment().format("DD-MM-YYYY_HH:mm:ss"),
            headers: true,
            column: {
                style: 'font-size:20px;background:#d1d1d1;'
            },

            columns: [
                { columnid: 'client', title: 'Client' },
                { columnid: 'commercial', title: 'Commercial' },
                { columnid: 'marque', title: 'Marque' },
                { columnid: 'categorie', title: 'Categorie' },
                { columnid: 'veuille_yes', title: 'Veuille yes' },
                { columnid: 'veuille_no', title: 'Veuille no' }
            ],
            row: {
                style: function (sheet, row, rowidx) {
                    return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                }
            }
        };

        WS.getData('clients/exportVeuilles')
            .then(function (response) {
                var filename = 'veuilles-' + moment().format("DD-MM-YYYY_HH:mm:ss");
                alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [config, response.data]);
                $scope.$apply();

            })
            .then(null, function (error) {
                console.log(error);
            });


    };


}
export default {
    name: 'clientController',
    fn: clientController
};