function authController($localStorage, $state, $scope, WS, $stateParams, $http, Flash) {
    'ngInject';
    const vm = this;

    vm.access = {}


    vm.login = function() {
        //var device_id = new DeviceUUID().get();
        // vm.access.device_id = device_id;
  
         WS.post('login', vm.access)
            .then(function(response) {

                if (response.status == 200) {
                    $localStorage.user = response.data.user;
                    if(response.data.user.email_verifie==0){
                        $localStorage.inviteToLogin = true;
                    }
                    $localStorage.user = response.data.user;
                    $localStorage.role = response.data.role;
                    $localStorage.dashboard = response.data.user.dashboard;
                    if ($localStorage.role.code == 'admin' || $localStorage.role.code == 'superviseur' || $localStorage.role.code == 'commercial') {
                        
                        if ($localStorage.dashboard == 'vente') {
                            $state.go('app.dashboard', {}, {
                                reload: false,
                                inherit: false
                            });
                            
                        }else{
                            $state.go('app.dashboardcommande', {}, {
                                reload: false,
                                inherit: false
                            });
                        }
                        
                        
                    } else if ($localStorage.role.code == 'magasinier') {

                        $state.go('app.users.listusers', {}, { reload: true });
                    }
                    else if ($localStorage.role.code == 'caissier') {
                        $state.go('app.chargements.stat-chargements', {},{ reload: true });

                    } else if($localStorage.role.code == 'responsable_vente') {

                        $state.go('app.bl.index', {}, { reload: true });
                        //$state.reload('app.clients');
                    } else if($localStorage.role.code == 'fournisseur') {

                        $state.go('app.stockDepot.index', {}, { reload: true });
                        //$state.reload('app.clients');
                    }else{
                        $state.go('app.clients.index', {}, { reload: true });
                    }
                    
                }
            }, function(error) {
                Flash.create('danger', error.data.message);
                $scope.$apply();
            }); 

    };



    WS.getData('infoc')
        .then(function (response) {
            vm.societes = response.data;
            var contrat_code = vm.societes.contrat;
            /*console.log(response);
            console.log(contrat_code);*/
            $http({
                method: 'GET',
                url: `https://api.sav.priorityexpeditions.com/api/v1/license/${contrat_code}`,
                //url: `https://api.sav.priorityexpeditions.com/api/v1/license/nomadis`,
                cache: false
            }).then(function successCallback(response) {
                //console.log($.isEmptyObject(response.data));
                //console.log('-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_Contrat_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-');

               
                vm.url_pub = response.data.url_pub;
                vm.texte_pub = response.data.texte_pub;

                vm.existBi = (vm.linkBi) ? true : false;
                if ($.isEmptyObject(response.data) == true) {
                    vm.expiration = true;
                    vm.expirationErrorContrat = true;
                    vm.alertExpiration = `Erreur de vérification de compte, veuillez contacter l'admin.`;
                } else {
                    //response.isactif="0";
                    if (response.data.isactif == "1") {
                        var currentday = new moment();
                        var lastday = moment(response.data.date_fin, "YYYY-MM-DD");
                        //Difference in number of days
                        var restime = moment.duration(lastday.diff(currentday)).asDays();
                        var restime = Number(restime.toFixed(0));
                        //console.log(restime);
                        //restime = 0;
                        if (restime <= 7 && restime > 0) {
                            vm.expiration = true;
                            vm.alertExpiration = `Il Vous reste ${restime} jours avant l'expiration de votre compte.`;
                        }
                        if (restime <= 0) {
                            vm.expiration = true;
                            vm.alertExpiration = `Votre compte a été expiré.`;
                        }
                    }
                    //console.log('-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_Contrat_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-');

                    else {
                        vm.expiration = true;
                        vm.alertExpiration = `Votre compte a été désactivé.`;
                    }
                    //console.log('-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_Contrat_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-');
                }
            }, function errorCallback(response) {
                vm.expiration = true;
                vm.expirationErrorContrat = true;
                vm.alertExpiration = `Erreur de verification de compte, veuillez contacter l'admin.`;
            });
        });

}
export default {
    name: 'authController',
    fn: authController
};