function visitesController(DatatableLang, $compile, $scope,$translate, WS, $window, $uibModal) {
    'ngInject';
    const vm = this;
    vm.visites = [];
    vm.search = {};
    vm.search.specialite =  '';
    vm.search.date_debut =  moment().format('YYYY-MM-DD');
    vm.search.date_fin = moment().format('YYYY-MM-DD');
    vm.group_clients = [];
    vm.check_client = {};

   
    vm.addTourneeCredit = function () {
        if (vm.group_clients) {
            var data = {
                //libelle: vm.libelle_tournee,
                depot_code: vm.depot_code,
                code_jour: vm.code_jour,
                frequence: vm.frequence,
                routing_code: vm.routing_code_tourne,
                date_debut: vm.date_debut,
                date_fin: vm.date_fin,
                group_clients: vm.group_clients,
                dates: vm.dates
            };
            
            WS.post('clients/addTourneeCredit', data)
                .then(function (response) {
                    if (response.data.success == true) {
                        swal(
                            $translate.instant('Succée'),
                            $translate.instant('Tournee Ajoutée avec succé'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        })
                    } else {
                        swal(
                            $translate.instant('Succée'),
                            $translate.instant(response.data.message),
                            'success'
                        );
                    }
                })
                .then(null, function (error) {
                    console.log(error);
                });
        } else {
            swal(
                $translate.instant('Oups!'),
                $translate.instant('Veuillez bien choisir au moins un client'),
                'warning'
            );

        }
    }

    vm.selectDepot = function () {
        WS.get('depots/selectDepot')
            .then(function (response) {
                vm.selectdepot = response.data;
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectRoutes = function () {
        WS.get('routing/selectRoutes')
            .then(function (response) {
                vm.routings = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    $(document).ready(function () {

        $(".datepicker-multiple").datepicker({
            autoClose: false,
            onSelect: function (date) {
                vm.dates = date;
                $scope.$apply();
                //console.log(vm.month);
            }
        });
    })

    vm.getHaversine = (latitude_client, longitude_client, latitude, longitude) => {
        const client = { latitude: parseFloat(latitude_client), longitude: parseFloat(longitude_client) }
        const visite = { latitude: parseFloat(latitude), longitude: parseFloat(longitude) }

        if(_.isNaN(client.latitude) || _.isNaN(client.latitude)){
            return 'PCI'
        }
        else if(_.isNaN(visite.latitude) || _.isNaN(visite.latitude)){
            return 'PVI'
        }else {
            var position = haversine(client, visite)
            if(position > 1000){
                position = position / 1000
                position =  position.toFixed(0) + ' Km';
            }else{
                position =  position.toFixed(0) + ' m';
  
            }
            return position;
        }
         //return distance en métre
    }

    vm.getVisites = function() {
        WS.getData('visites/getVisitesDashboard', vm.search)
            .then(function(response) {
                if (response.status == 200) {
                    vm.visites = response.data;
                    vm.group_clients = [];
                    vm.check_client = {};
                    vm.check_all=false;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };


    vm.exportToExcel = function () {
      


        var filename = 'visites-' + moment().format("DD-MM-YYYY_HH:mm:ss");
        WS.getData('visites/getVisitesDashboard', vm.search).then(
            function (response) {
                alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsv, response.data]);
                alasql('SELECT * INTO CSV("' + filename + '.csv",?) FROM ?', [stylecsv, response.data]);
            },
            function (error) {
                console.log(error);
            }
        );
    };


    var stylecsv = {
        sheetid: 'visites - ' + moment().format("DD-MM-YYYY HH:mm:ss"),
        headers: true,
     
        column: {
            style: 'font-size:20px;background:#d1d1d1;'
        },
        columns: [
            { columnid: 'id' },
            { columnid: 'visite_code', title: 'Code' },
            { columnid: 'date_visite', title: 'Date' },
            { columnid: 'type', title: 'Type' },
            { columnid: 'client_code', title: 'Code client' },
            { columnid: 'client', title: 'Client' },
            { columnid: 'activite', title: 'Activite' },
            { columnid: 'magasin', title: 'Magasin' },
            { columnid: 'activite', title: 'Activite' },
            { columnid: 'zone', title: 'Zone' },
            { columnid: 'mobile', title: 'Mobile' },
            { columnid: 'longitude', title: 'longitude' },
            { columnid: 'latitude', title: 'latitude' },
            { columnid: 'latitude_client', title: 'latitude_client' },
            { columnid: 'longitude_client', title: 'longitude_client' },
            { columnid: 'distance', title: 'distance' },
            { columnid: 'commentaire', title: 'commentaire' },
            { columnid: 'cause', title: 'cause' },
            { columnid: 'heure_debut', title: 'heure debut' },
            { columnid: 'heure_fin', title: 'heure fin' }
         
        ],
        row: {
            style: function (sheet, row, rowidx) {
                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
        }
    };



    vm.check_box_all = () => {

        if (vm.check_all == true) {
            vm.group_clients = [];
            var temp = {};
            _.each(vm.visites, (element, index) => {
                temp[element.client_code] = element.client_code;
            });

            _.each(temp, (element, index) => {
                vm.group_clients.push(element);
                vm.check_client[element] = true;
            });
        }
        if (vm.check_all == false) {
            vm.group_clients = [];
        }
        console.log(vm.group_clients);
    };

    vm.manage_checks = (value, client_code) => {

        if (value == true) {
            vm.group_clients.push(client_code);
        }
        if (value == false) {
            vm.group_clients = _.without(vm.group_clients, client_code);
        }
        console.log(vm.group_clients)
    };

    vm.showCurrentMap = (visite) =>{
        vm.selectmapsclients = angular.copy(vm.clients);
        vm.centerroutes = angular.copy(visite.latitude + ', ' + visite.longitude);
        vm.zoomRouting = 15;
        $('#container-maps').empty();
        $('#container-maps').html('<div style="width: 100%; height: 500px" id="mapContainerClients"></div>');

        var mymap = L.map('mapContainerClients').setView([visite.latitude, visite.longitude], vm.zoomRouting);


        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
            foo: 'bar',
            attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
        })
            .addTo(mymap);


            /**
             * marker visite
             */
            var redMarker = L.ExtraMarkers.icon({
                icon: 'fa-binoculars',
                markerColor:  'blue',
                shape: 'circle', //'circle', 'square', 'star', or 'penta'
                prefix: 'fa'
            });

            var marker = L.marker([visite.latitude, visite.longitude], { icon: redMarker }).addTo(mymap);
            var info = $compile(`
                                 <div id="bodyContent" style="width=300px;">
                                      <p class="text-center">
                                        <a ui-sref="app.clients.details({id : ${visite.client_id} })" target="_blank">
                                            Modifier
                                        </a>
                                        <b> | </b>
                                        <a ui-sref="app.clients.historiques({code : ${visite.client_code}})" target="_blank">
                                          Historique
                                        </a>
                                      </p>
                                      <h3> Visite</h3></br>

                                      <b>Client</b> : ${visite.client}</a></br>
                                      <b>Code</b> : ${visite.client_code}</a></br>
                                      <b>Code a barre</b> : ${visite.code_a_barre}</a></br>
                                      <b>Route</b> : ${visite.routing_code}</br>
                                      <b>Mobile</b> : ${visite.mobile}</br>
                                      <b>Commercial</b> : ${visite.user}</br>
                                    </div>
                                `)($scope);
            var popup = L.popup({
                maxWidth: 600,
                minWidth: 200
            }).setContent(info[0])
            marker.bindPopup(popup);


            /**
             * marker Client
             */
            var redMarker = L.ExtraMarkers.icon({
                icon: 'fa-home',
                markerColor:  'red',
                shape: 'circle', //'circle', 'square', 'star', or 'penta'
                prefix: 'fa'
            });

            var marker = L.marker([visite.latitude_client, visite.longitude_client], { icon: redMarker }).addTo(mymap);
            var info = $compile(`
                                 <div id="bodyContent" style="width=300px;">
                                      <p class="text-center">
                                        <a ui-sref="app.clients.details({id : ${visite.client_id} })" target="_blank">
                                            Modifier
                                        </a>
                                        <b> | </b>
                                        <a ui-sref="app.clients.historiques({code : ${visite.client_code}})" target="_blank">
                                          Historique
                                        </a>
                                      </p>
                                      <h3> Position de Client</h3></br>
                                      <b>Client</b> : ${visite.client}</a></br>
                                      <b>Code</b> : ${visite.client_code}</a></br>
                                      <b>Code a barre</b> : ${visite.code_a_barre}</a></br>
                                      <b>Route</b> : ${visite.routing_code}</br>
                                      <b>Mobile</b> : ${visite.mobile}</br>
                                      <b>Commercial</b> : ${visite.user}</br>
                                    </div>
                                `)($scope);
            var popup = L.popup({
                maxWidth: 600,
                minWidth: 200
            }).setContent(info[0])
            marker.bindPopup(popup);

            setTimeout(function() {
                mymap.invalidateSize();
              }, 1000);

            console.log(visite.latitude_client, visite.latitude_client, visite.latitude, visite.longitude);
    }

    vm.selectUsers = function () {
        WS.get('users/selectUsers', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.affectationImageClient = function (obj, index) {
    
        vm.panel_change_image = $uibModal.open({
            animation: true,
            templateUrl: 'panel_change_image',
            size: 'lg',
            backdrop: true,
            controller: function ($scope, $translate) {
                $scope.obj = obj;
                console.log('$scope.obj.', $scope.obj);

                var data = {
                    img_visite_code : $scope.obj.visite_code, // image de client  : client_code and type = 'cover'
                    img_client_barre_code : $scope.obj.code_a_barre, // image de visite  : visite_code and type = 'visite'
                };
                //console.log('data', data);

                $scope.submitAffectationImageClient = function () {
                    vm.submitAffectationImageClient(data, index);
                };
            }
        });



        
    };

    vm.submitAffectationImageClient = (data, index) => {
        console.log(data);
        WS.post('images/affectationImageClient', data).then(
            function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succés'),
                        $translate.instant('Client modifié avec succès'),
                        'success'
                    ).then(function () {
                        vm.panel_change_image.close({});
                    }).catch(function () {
                        vm.panel_change_image.close({});
                    });

                    vm.visites[index].cover_img = data.img_to_update;
                    

                } else {
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant('erreur'),
                        'warning'
                    );
                }
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            }
        );
    }

    vm.getImagesByCodeABarre = (code_a_barre) => {
        WS.get('clients/getImagesByCodeABarre/' + code_a_barre)
            .then(function (response) {
                if (response.data.length > 0) {
                    vm.imagesclient = response.data;
                    jQuery.noConflict();
                    $('#imagesClient').modal('show');
                    $scope.$apply();
                } else {
                    alert($translate.instant('Aucune image correspond a ce client'));
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.deleteVisite = function (code) {
        //console.log(code);
        //console.log(user);
        swal({
            title: $translate.instant(`Suppression`),
            // text: $translate.instant('code bl') + ` : `,
            html: `${$translate.instant('Vos étes sur le point de supprimer cette visite')}
                    <h4 class="text-center">${code}</h4>`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui, supprimer!'),
            cancelButtonText: $translate.instant('Non, Annuler!'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            $uibModal.open({
                animation: true,
                templateUrl: 'VerificationOfPassword',
                size: 'md',
                backdrop: true,
                controller: function ($scope, $translate) {
                    $scope.message_password = $translate.instant('confirmer le mot de passe');
                    $scope.current_numero = code;

                    $scope.deleteVisite = function () {
                        if ($scope.set_password != '' && $scope.set_password != undefined) {
                            var data = {
                                numero: $scope.current_numero,
                                password: $scope.set_password
                            };
                            console.log(data);
                            WS.post('visites/deleteVisiteFromDash', data).then(
                                function (response) {
                                    if (response.data.success) {
                                        swal(
                                            $translate.instant('Succès'),
                                            $translate.instant('Supprimée'),
                                            'success'
                                        ).then(function () {
                                            $window.location.reload();
                                        }).catch(function () {
                                            $window.location.reload();
                                        });;
                                    } else {
                                        swal(
                                            $translate.instant('Attention'),
                                            $translate.instant(response.data.message),
                                            'warning'
                                        );
                                    }
                                },
                                function () {
                                    swal(
                                        $translate.instant('Attention'),
                                        $translate.instant('erreur de chargement des données'),
                                        'warning'
                                    );
                                }
                            );
                        } else {
                            swal(
                                $translate.instant('Attention'),
                                $translate.instant('Mot de passe Obligatoire'),
                                'warning'
                            );
                        }
                    }
                }
            });

        });

    };




    vm.getCategories = function () {
        WS.get('categoriescommerciale')
            .then(function (response) {
                if (response.status == 200) {

                    vm.categories = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.selectActivites = () => {
        WS.get('activites/selectActivites')
            .then(function (response) {
                vm.activites = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectZones = () => {
        WS.get('zones/selectZones')
            .then(function (response) {
                vm.zones = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectRegions = () => {
        WS.get('regions/selectRegions')
            .then(function (response) {
                vm.regions = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.selectSpecialites = () => {
        WS.get('specialites')
            .then(function (response) {
                vm.specialites = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectGouvernorats = () => {
        WS.get('gouvernorats/selectGouvernorats')
            .then(function (response) {
                vm.gouvernorats = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectDelegations = function () {
        WS.get('delegations/selectDelegations')
            .then(function (response) {
                if (response.status == 200) {
                    vm.delegations = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.printTableHtml = function PrintElem(elem) {
        var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`<html>
                                    <head>
                                    <title> Bon de Chargement </title>
                                    <style>.noprint,.no-print{display: none;}.text-center{text-align:center}</style>
                                    <style>
                                        th.text-center{border:1px solid #ddd!important; font-size:12px!important;}
                                        td.text-center{border:1px solid #ddd!important; font-size:12px!important;}
                                        h4,h5{margin: 4px;}
                                    </style>
                                `);
        mywindow.document.write('</head><body>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    };

}
export default {
    name: 'visitesController',
    fn: visitesController
};